(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("calendarEditEventController", calendarEditEventController);

	function calendarEditEventController($rootScope, $scope, $mdDialog, $filter, $translate, $timeout, $http, $sce,
		$stateParams, $location, $q, errorHandling, successHandling, userDataService, coreData, coreDataCalendar, emailValidationService,
		apiCategories, coreDataCategories, coreDataFileStorage, coreDataContacts, errorMessageService, userTimeService, coreLicensing, themesService,
		meetingWorkspaces, calendarRecurrenceDescriptionService, htmlSandboxing, i18n) {

		var vm = this;

		// Online Meeting settings options
		vm.availableEndBehaviors = [
			{ id: 0, desc: $translate.instant("ARCHIVE_MEETING") },
			{ id: 1, desc: $translate.instant("DELETE_MEETING") },
			{ id: 2, desc: $translate.instant("KEEP_ACTIVE") }
		];
		vm.availableUploadPermissions = [
			{ id: 0, desc: $translate.instant("ORGANIZER_ONLY") },
			{ id: 1, desc: $translate.instant("AUTHENTICATED_ATTENDEES") },
			{ id: 2, desc: $translate.instant("EVERYONE") }
		];

		$scope.recurrenceType = {
			ONCE: 0,
			DAILY: 4,
			WEEKLY: 5,
			MONTHLY: 6,
			YEARLY: 7
		};
		$scope.meetingType = {
			IsAMeeting: 1,
			IsMeetingReceived: 3
		}
		$scope.eventStatus = {
			Tentative: 0,
			Confirmed: 1,
			Cancelled: 2,
			NeedsAction: 3,
			Completed: 4,
			InProcess: 5
		}
		$scope.isInitialized = false;
		$scope.recurrenceChanged = false;
		$scope.isWebCalendar = false;
		$scope.endBeforeStart = false;
		$scope.participantStatus = 0;
		vm.availableTimeZones = [];
		vm.showTimeZones = false;
		vm.showTimeZoneWarning = false;
		vm.editSeries = false;
		vm.isSourceOwner = false;
		vm.availabilitySet = false;
		vm.removedAttachedFiles = [];
		Object.defineProperty(vm, "userTimeZone", {
			get: function () { return userTimeService.userTimeZone; }
		});
		vm.timeZoneSearch = "";
		vm.showAvailability = false;
		vm.hasMeeting = false;
		vm.allowTeamWorkspaces = false;
		vm.meetingCreated = false;
		vm.meetingRemoved = false;
		vm.meetingMissing = false;

		// Functions
		$scope.delete = deleteAppointment;
		$scope.acceptInvite = acceptInvite;
		$scope.tentativelyAcceptInvite = tentativelyAcceptInvite;
		$scope.acceptProposal = acceptProposal;
		$scope.addAttendee = addAttendee;
		$scope.addResource = addResource;
		$scope.exportIcs = exportIcs;
		$scope.removeAttendee = removeAttendee;
		$scope.removeResource = removeResource;
		$scope.changeAvailHour = changeAvailHour;
		$scope.changeAvailDate = changeAvailDate;
		$scope.createTempMeeting = createTempMeeting;
		$scope.editMeetingDisabled = editMeetingDisabled;
		$scope.getAvailHour = getAvailHour;
		$scope.isUnavailable = isUnavailable;
		$scope.needEndBehavior = needEndBehavior;
		$scope.removeAutocompleteEntry = removeAutocompleteEntry;
		$scope.onValueChanged = onValueChanged;
		$scope.onCategoryChanged = onCategoryChanged;
		$scope.onClearAllCategories = onClearAllCategories;
		$scope.openManageCategoriesModal = openManageCategoriesModal;
		$scope.openProposeChangesModal = openProposeChangesModal;
		$scope.hasSelectedCategory = hasSelectedCategory;
		$scope.categoryColorByName = categoryColorByName;
		$scope.duplicate = duplicate;
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.declineInvite = declineInvite;
		$scope.timeZoneStart = undefined;
		$scope.timeZoneEnd = undefined;
		$scope.toggleRecurringDelete = toggleRecurringDelete;
		$scope.onSourceChanged = onSourceChanged;
		$scope.openRecipientsModal = openRecipientsModal;
		$scope.openResourceRecipeintsModal = openResourceRecipeintsModal;
		$scope.removeMeeting = removeMeeting;
		$scope.viewAvailability = viewAvailability;
		$scope.querySearch = querySearch;
		$scope.querySearchResources = querySearchResources;
		$scope.setForm = setForm;
		$scope.setFormDirty = setFormDirty;
		vm.allDayChanged = allDayChanged;
		vm.checkForConflicts = checkForConflicts;
		vm.copyLink = copyLink;
		vm.goToSeries = goToSeries;
		vm.isOwner = isOwner;
		vm.shouldShowEmailNotification = shouldShowEmailNotification;
		vm.handleResourceAddAndLocation = handleResourceAddAndLocation;
		vm.handleResourceRemoveAndLocation = handleResourceRemoveAndLocation;
		vm.addConfRoomToLoaction = addConfRoomToLoaction;
		vm.clearSearchTerm = function () { vm.timeZoneSearch = ""; };
		vm.removeAttachedFile = removeAttachedFile;
		$scope.afContext = "user";
		activate();
		$scope.$on("$destroy", destroy);

		var watchersRegistered = false;
		var skipNextStartWatch = false;
		var skipNextEndWatch = false;

		//////////////////////

		function activate() {
			themesService.ensureActivated();
			$timeout(function () {
				$("#timeZoneSearchA").on("keydown", function (ev) { ev.stopPropagation(); });
				$("#timeZoneSearchB").on("keydown", function (ev) { ev.stopPropagation(); });
				$("#timeZoneSearchC").on("keydown", function (ev) { ev.stopPropagation(); });
				$("#timeZoneSearchD").on("keydown", function (ev) { ev.stopPropagation(); });
			});

			userDataService
				.init()
				.then(
					function () {
						const features = userDataService.user.settings.features;
						vm.allowTeamWorkspaces = features.enableWebConferencing && coreLicensing.edition !== 1;
					},
					function () { });

			// Catch the keydown for the entire entire
			$(document).keydown(function (e) {
				// Set self as the current item in focus
				var self = $(":focus");
				function tabKey() {
					if (e.which === 9 && self.is("input[type=search]")) {
						if (self[0].id.indexOf("timeZoneSearch") === -1) {
							addAttendee(e);
							e.preventDefault();
							return false;
						}
					}
				}

				tabKey();
			});

			$http
				.get("~/api/v1/settings/permissions")
				.then(
					function (data) { vm.showAvailability = data.data.permissions.calendarPublicAvailability; },
					function () { });

			coreData
				.init()
				.then(onCoreDataInit, errorHandling.report);

			$scope.$on("categoriesUpdated", onCategoryListModified);

			function onCoreDataInit() {
				userTimeService
					.init()
					.then(
						function () {
							$scope.timeZoneStart = userTimeService.userTimeZone;
							$scope.timeZoneEnd = userTimeService.userTimeZone;
							vm.availableTimeZones = userTimeService.availableTimeZones;

							coreDataCalendar
								.loadSources()
								.then(onSourcesLoaded, errorHandling.report);
						},
						function () { });
			}

			function onSourcesLoaded() {
				var startDate = null;
				$stateParams.data = {};
				if (localStorage.apptPopout) {
					var apptPopout = JSON.parse(localStorage.apptPopout);
					startDate = apptPopout.start || (apptPopout.data ? apptPopout.data.start : undefined);
					if (startDate) startDate = moment.tz(startDate, $scope.timeZoneStart.location);
					if (apptPopout.data) {
						apptPopout.data.start = startDate ? startDate.toDate() : apptPopout.data.start;
						$stateParams.data = apptPopout.data;
						$scope.currentView = apptPopout.data.currentView;
						if ($stateParams.data.description)
							$scope.description = apptPopout.data.isHtml ? $stateParams.data.description : linkifyPlainText($stateParams.data.description);

					} else {
						$stateParams.data = {};
					}
					if (apptPopout.calId) $stateParams.calId = apptPopout.calId;
					if (apptPopout.owner) $stateParams.owner = apptPopout.owner;
					delete localStorage.apptPopout;
					$scope.isDuplicating = apptPopout.duplicate;
				}

				var queryString = $location.search();
				var owner = vm.owner = $stateParams.owner || coreData.user.username;
				if (owner === "null") { owner = null; }
				else {
					apiCategories.categoryOwner = `${vm.owner}@${userDataService.user.domain}`;
				}
				var calId = vm.calId = $stateParams.calId;
				var id = vm.id = $stateParams.id;
				var calendars = coreDataCalendar.getCalendars();
				if (id !== null && id !== "new") {
					var currentCal = $.grep(calendars, function (cal) { return cal.owner === owner && cal.id === calId; })[0];

					$scope.isWebCalendar = currentCal && currentCal.isWebCalendar;
					$rootScope.spinner.show();

					if (queryString && queryString.instanceId) {
						var instanceDate = moment.utc(queryString.instanceId, "YYYYMMDDHHmmss");
						var params = JSON.stringify({ occurrenceId: instanceDate });
						$http
							.post("~/api/v1/calendars/events/occurrence/" + owner + "/" + calId + "/" + id, params)
							.then(onLoadSuccess, errorHandling.report)
							.finally($rootScope.spinner.hide);
					}
					else {
						$http
							.get("~/api/v1/calendars/events/" + owner + "/" + calId + "/" + id)
							.then(onLoadSuccess, errorHandling.report)
							.finally($rootScope.spinner.hide);
					}
				} else {
					calendars = $.grep(calendars, function (cal) { return cal.permission >= 8; });
					var cals = $.grep(calendars, function (cal) { return cal.isPrimary === true && cal.owner === owner; });
					if (cals.length > 0 && !cals[0].isPrimary) {
						if (calId !== cals[0].id)
							calId = vm.calId = cals[0].id;
					}
					// Round to nearest half hour
					const roundToNext30Min = function (mDate) {
						mDate.seconds(0);
						const mins = mDate.minute();
						if (mins > 30) {
							mDate = mDate.startOf("hour").add(1, "hour");
						} else if (mins > 0) {
							mDate = mDate.startOf("hour").add(30, "m");
						}
						return mDate;
					}

					let start = $stateParams.data.start || startDate
						? moment($stateParams.data.start || startDate)
						: moment.tz(moment().add(- moment().utcOffset(), "m").format("YYYY-MM-DDTHH:mm"),
							"UTC");
					start = roundToNext30Min(start);

					
					var allDay = $stateParams.data.allDay || coreDataCalendar.calendarSettings.defaultDuration === 1440;
					var duration = coreDataCalendar.calendarSettings.defaultDuration;
					if (allDay)
						duration = 60;	// For all-day events we use default 60 minutes duration otherwise it may span multiple days

					var details = {
						id: null,
						calendarId: calId,
						calendarOwner: owner,
						allDay: allDay,
						start: { dt: start.toDate(), tz: $scope.timeZoneStart.location },
						end: { dt: moment(start).add(duration, "m").toDate(), tz: $scope.timeZoneEnd.location },
						subject: $stateParams.data.subject || "",
						description: $stateParams.data.description || "",
						isHtml: $stateParams.data.isHtml,
						isPrivate: false,
						location: "",
						availability: allDay ? 0 : 2,	// Free if allDay, otherwise Busy
						emailNotification: coreData.user.username + "@" + coreData.user.domain,
						emailNotificationEnabled: false,
						reminderIndex: allDay ? 0 : coreDataCalendar.calendarSettings.defaultReminder, // No reminders if all day, otherwise defaults to user setting
						organizerName: $stateParams.data.organizerName || "",
						organizerEmail: $stateParams.data.organizerEmail || "",
						attendees: $stateParams.data.attendees || [],
						categories: apiCategories.getCategories(),
						recurrence: {
							type: 0,
							untilCount: 10,
							dailyInterval: 1,
							weeklyInterval: 1,
							weeklyDays: [false, false, false, false, false, false, false],
							monthlyInterval: 1,
							monthDay: start.date(),
							monthlySpecificDay: true,
							monthlyPeriodIndex: Math.ceil(start.date() / 7) - 1,
							monthlyPeriodDayIndex: start.day(),
							yearlyInterval: 1,
							yearlyMonthIndex: start.month(),
							yearlyDay: start.date(),
							yearlySpecificDate: true,
							yearlyPeriodIndex: Math.ceil(start.date() / 7) - 1,
							yearlyPeriodDayIndex: start.day(),
							untilDate: start.add(1, "y").startOf("day").add(1, "d").add(-1, "s").utc(),
						},
						resourceId: "",
						permission: 8, // Full permission
						isTentative: false,
						attachedFiles: [],
						disallowCounters: $stateParams.data.disallowCounters,
						allowReplies: $stateParams.data.allowReplies,
						participantStatus: $stateParams.data.participantStatus,
					};
					if (details.subject) vm.eventForm.$setDirty();
					details.recurrence.weeklyDays[start.day()] = true;
					onLoadSuccess({ data: { details: details } });
				}

				function onLoadSuccess(success) {
					if (watchersRegistered) {
						skipNextEndWatch = true;
						skipNextStartWatch = true;
					}

					vm.originalDetails = angular.copy(success.data.details);
					if (vm.originalDetails.start.tz === "Universal")
						vm.originalDetails.start.tz = "UTC";
					if (vm.originalDetails.end.tz === "Universal")
						vm.originalDetails.end.tz = "UTC";
					if (vm.originalDetails.start.tz === "Russia Time Zone 11") {
						vm.startTzRussiaTimeZone11 = true;
						vm.originalDetails.start.tz = "Asia/Kamchatka";
					}
					if (vm.originalDetails.end.tz === "Russia Time Zone 11") {
						vm.endTzRussiaTimeZone11 = true;
						vm.originalDetails.end.tz = "Asia/Kamchatka";
					}
					if (!moment.tz.zone(vm.originalDetails.start.tz)) {
						vm.originalDetails.start.tz = $scope.timeZoneStart.location || "UTC";
					}
					if (!moment.tz.zone(vm.originalDetails.end.tz)) {
						vm.originalDetails.end.tz = $scope.timeZoneEnd.location || "UTC";
					}
					var browserOffset = moment(vm.originalDetails.start.dt).utcOffset();
					var timezoneOffset = moment.tz(moment(), vm.originalDetails.start.tz).utcOffset();
					$scope.isRecurring = vm.originalDetails.recurrence.type !== 0;
					vm.editSeries = !$scope.isRecurring || (queryString && !queryString.instanceId);
					if (!vm.editSeries) {
						const offset = timezoneOffset - browserOffset;
						$scope.instanceStart = moment.tz(vm.originalDetails.start.dt, vm.originalDetails.start.tz).utc().add(offset, "m").toDate();
						$scope.instanceEnd = moment.tz(vm.originalDetails.end.dt, vm.originalDetails.end.tz).utc().add(offset, "m").toDate();
					}
					apiCategories.categoryOwner = `${owner}@${userDataService.user.domain}`;
					success.data.details.categories = success.data.details && success.data.details.categories ?
						setupCategories(apiCategories.getCategories(), success.data.details.categories) :
						apiCategories.getCategories();
					populateInfo();
					vm.attachedFiles = $scope.details.attachedFiles ? JSON.parse(JSON.stringify($scope.details.attachedFiles)) : [];
					vm.isSourceOwner = owner === userDataService.user.username;

					if (vm.isSourceOwner && vm.originalDetails.meetingUrl) {
						let index = vm.originalDetails.meetingUrl.indexOf("/meeting#/");
						if (index > -1) {
							index += "/meeting#/".length;
							var fullId = vm.originalDetails.meetingUrl.substring(index);
							meetingWorkspaces.getMeetings()
								.then(
									function () {
										const meetings = $.grep(meetingWorkspaces.meetings, mtg => mtg.fullId === fullId);
										if (meetings && meetings.length > 0) {
											vm.onlineMeeting = meetings[0];
											vm.onlineMeeting.uploadPermissionLevel =
												(vm.onlineMeeting.uploadPermissionLevel === null || vm.onlineMeeting.uploadPermissionLevel === undefined) ? 0 : vm.onlineMeeting.uploadPermissionLevel;
											vm.onlineMeeting.endMeetingBehavior =
												(vm.onlineMeeting.endMeetingBehavior === null || vm.onlineMeeting.endMeetingBehavior === undefined) ? 0 : vm.onlineMeeting.endMeetingBehavior;

											vm.onlineMeeting.occurenceSpecific = !!$location.search().instanceId &&
												!!vm.onlineMeeting.scheduledStart &&
												!!vm.onlineMeeting.scheduledEnd;
										} else
											vm.meetingMissing = true;
									},
									function () { });
						}
					}

					$timeout(function () {
						$('[name="subject"]').trigger("focus");
					});

					function populateInfo() {
						var start = success.data.details.start;
						if ($stateParams.data.start) {
							if (moment.isMoment($stateParams.data.start))
								start = $stateParams.data.start.toDate();
							else if (typeof $stateParams.data.start === "string") {
								if (!vm.originalDetails.allDay)
									start = new Date($stateParams.data.start);
								else {
									var mjs = moment($stateParams.data.start);
									start = moment.utc({
										year: mjs.year(),
										month: mjs.month(),
										date: mjs.date()
									}).toDate();
								}
							}
						}

						switch (success.data.details.participantStatus) {
							case "ACCEPTED":
								$scope.participantStatus = 1;
								break;
							case "TENTATIVE":
								$scope.participantStatus = 2;
								break;
							case "NEEDS-ACTION":
								$scope.participantStatus = 0;
								break
							default:
								$scope.participantStatus = 0;
								break;
						}

						$scope.eventInfo = {
							info: success.data.details,
							calendars: calendars,
							owner: owner,
							calId: calId,
							instanceStart: start,
							isNew: id === "new" || id === null
						};

						$scope.isNew = $scope.eventInfo.isNew;
						$scope.details = $scope.eventInfo.info;
						$scope.calendars = $scope.eventInfo.calendars;

						if ($scope.calendars[0].name.toLowerCase() === "my calendar" || $scope.calendars[0].untranslatedName === "MY_CALENDAR")
							$scope.calendars[0].name = $translate.instant("MY_CALENDAR");


						$scope.owner = $scope.eventInfo.owner;
						$scope.calId = $scope.eventInfo.calId;
						$scope.showRecurringDelete = false;

						$scope.availabilityOptions = generateAvailabilityOptions();
						$scope.daysOfWeek = generateDaysOfWeek();
						$scope.months = generateMonths();
						$scope.reminderOptions = generateReminderOptions();
						$scope.recurrenceOptions = generateRecurrenceOptions();
						$scope.recurPeriods = generateFrequencyPeriods();
						$scope.currentCalendar = getAssociatedCalendar();
						$scope.permission = $scope.currentCalendar.permission;
						$scope.uid = $scope.details.id;
						$scope.subject = $scope.details.subject;
						$scope.allDay = $scope.details.allDay;
						$scope.allowReplies = $scope.details.allowReplies;

						if (!vm.originalDetails.start.tz)
							vm.originalDetails.start.tz = $scope.timeZoneStart.location;
						if (!vm.originalDetails.end.tz)
							vm.originalDetails.end.tz = $scope.timeZoneEnd.location;

						vm.hasMeeting = !!$scope.details.meetingUrl;

						var startOffset = moment.tz(vm.originalDetails.start.dt, vm.originalDetails.start.tz).utcOffset() - browserOffset;
						var momentStart = moment.tz(vm.originalDetails.start.dt, vm.originalDetails.start.tz);
						var endOffset = moment.tz(vm.originalDetails.end.dt, vm.originalDetails.end.tz).utcOffset() - browserOffset;
						var momentEnd = moment.tz(vm.originalDetails.end.dt, vm.originalDetails.end.tz);

						if ($scope.allDay) {
							vm.momentStartJS = momentStart.toDate();
							vm.momentStartJSDate = $filter("date")(vm.momentStartJS, "shortDate");
							vm.momentStartJSTime = $filter("date")(vm.momentStartJS, "shortTime");
						} else {
							userTimeService
								.convertLocalToUserTime(momentStart)
								.then(
									function (success) { 
										vm.momentStartJS = success;
										vm.momentStartJSDate = $filter("date")(success, "shortDate");
										vm.momentStartJSTime = $filter("date")(success, "shortTime");
									},
									function (failure) { errorHandling.report(failure); });
						}

						if (!$scope.allDay) {
							if ($scope.isNew) {
								$scope.start = moment(momentStart).add("m", startOffset).utc().toDate();
								$scope.end = moment(momentEnd).add("m", endOffset).utc().toDate();
							} else {
								$scope.start = moment(momentStart).add("m", startOffset).utc().toDate();
								$scope.end = moment(momentEnd).add("m", endOffset).utc().toDate();
							}
						} else {
							if ($scope.isNew) {
								$scope.start = moment(momentStart).add("m", startOffset).utc().toDate();
								$scope.end = moment(momentEnd).add("m", endOffset).utc().toDate();
							} else {
								$scope.start = moment(vm.originalDetails.start.dt.substr(0, 19)).toDate();
								$scope.end = moment(vm.originalDetails.end.dt.substr(0, 19)).toDate();
							}
						}
						if ($scope.isNew) {
							$scope.timeZoneStart = $.grep(vm.availableTimeZones, function (tz) { return tz.index === userTimeService.userTimeZone.index; })[0];
							$scope.timeZoneEnd = $scope.timeZoneStart;
						} else {
							if (vm.startTzRussiaTimeZone11 === true) {
								$scope.timeZoneStart = $.grep(vm.availableTimeZones, function (tz) { return tz.id === "Russia Time Zone 11"; })[0];
							} else {
								let tzInfo = vm.availableTimeZones.find(function (tz) { return tz.location === vm.originalDetails.start.tz; });
								// if the moment timezone can be found we convert the time to the users timezone
								if (!tzInfo && moment.tz.zone(vm.originalDetails.start.tz) && !$scope.isAllDay) {
									$scope.start = userTimeService.convertLocalToUserTimeMoment(moment(vm.originalDetails.start.dt).toDate()).toDate();
									$scope.timeZoneStart = userTimeService.userTimeZone;
								} else {
									$scope.timeZoneStart = tzInfo || userTimeService.userTimeZone;
								}
							}

							if (vm.endTzRussiaTimeZone11 === true) {
								$scope.timeZoneEnd = $.grep(vm.availableTimeZones, function (tz) { return tz.id === "Russia Time Zone 11"; })[0];
							} else {
								let tzInfo = vm.availableTimeZones.find(function (tz) { return tz.location === vm.originalDetails.end.tz; });
								// if the moment timezone can be found we convert the time to the users timezone
								if (!tzInfo && moment.tz.zone(vm.originalDetails.end.tz) && !$scope.isAllDay) {
									$scope.end = userTimeService.convertLocalToUserTimeMoment(moment(vm.originalDetails.end.dt).toDate()).toDate();
									$scope.timeZoneEnd = userTimeService.userTimeZone;
								} else {
									$scope.timeZoneEnd = tzInfo || userTimeService.userTimeZone;
								}
							}
						}

						vm.showTimeZoneWarning = !$scope.allDay && ($scope.timeZoneStart ? $scope.timeZoneStart.index : userTimeService.userTimeZone.index) !== userTimeService.userTimeZone.index;
						vm.showTimeZones = !$scope.allDay && (vm.showTimeZoneWarning || ($scope.timeZoneEnd ? $scope.timeZoneEnd.index : userTimeService.userTimeZone.index) !== userTimeService.userTimeZone.index);

						$scope.privateEvent = $scope.details.isPrivate;
						$scope.location = $scope.details.location;

						$scope.description = $scope.details.isHtml ? $scope.details.description : linkifyPlainText($scope.details.description);

						$scope.currentAvailability = getAvailability($scope.details.availability);
						$scope.currentReminder = $scope.reminderOptions[$scope.details.reminderIndex];
						$scope.emailNotification = $scope.details.emailNotification;
						$scope.emailNotificationEnabled = $scope.details.emailNotificationEnabled;
						$scope.currentRecurrence = getRecurrenceType($scope.details.recurrence.type);

						vm.recurUntilOption = $scope.details.recurrence.untilCountEnabled ? 1 : $scope.details.recurrence.untilDateEnabled ? 2 : 0;
						vm.recurUntilCount = $scope.details.recurrence.untilCount;
						$scope.recurUntilDate = moment($scope.details.recurrence.untilDate).toDate();

						vm.dailyFreq = $scope.details.recurrence.isWeekdaysOnly ? 1 : 0;
						vm.dailyFreqDays = $scope.details.recurrence.dailyInterval;

						vm.weeklyFreqWeeks = $scope.details.recurrence.weeklyInterval;
						vm.weeklyDays = $scope.details.recurrence.weeklyDays;

						$scope.monthlyFreq = $scope.details.recurrence.monthlySpecificDay ? 0 : 1;
						$scope.monthlyFreqMonths = $scope.details.recurrence.monthlyInterval;
						$scope.monthlyFreqDay = $scope.details.recurrence.monthDay;
						if ($scope.details.recurrence.monthlyPeriodIndex === 2147483647)
							$scope.details.recurrence.monthlyPeriodIndex = 4;
						$scope.monthlyFreqPeriod = $scope.recurPeriods[$scope.details.recurrence.monthlyPeriodIndex];
						$scope.monthlyFreqPeriodDay = $scope.daysOfWeek[$scope.details.recurrence.monthlyPeriodDayIndex];
						$scope.yearlyFreq = $scope.details.recurrence.yearlySpecificDate ? 0 : 1;
						$scope.yearlyFreqYears = $scope.details.recurrence.yearlyInterval;
						$scope.yearlyFreqMonth = $scope.details.recurrence.yearlyMonthIndex;
						vm.yearlyFreqMonthDate = $scope.details.recurrence.yearlyDay;
						$scope.yearlyFreqPeriod = $scope.details.recurrence.yearlyPeriodIndex;
						$scope.yearlyFreqPeriodDay = $scope.details.recurrence.yearlyPeriodDayIndex;
						$scope.yearlyFreqPeriodMonth = $scope.details.recurrence.yearlyMonthIndex;

						$scope.categories = $scope.details.categories;

						$scope.attendeeItem = "";
						$scope.attendeeField = "";
						$scope.resourceItem = "";
						$scope.resourceField = "";
						$scope.attendees = $stateParams.data.attendees ? $stateParams.data.attendees : $.extend(true, [], $scope.details.attendees.filter(att => !att.isResource));
						$scope.resources = $stateParams.data.attendees ? [] : $.extend(true, [], $scope.details.attendees.filter(att => att.isResource));
						if ($scope.attendees.length === 1 && $scope.attendees[0].isOrganizer)
							$scope.attendees = [];
						if ($stateParams.data.attendees) {
							$scope.attendeesChanged = true;
						}
						$scope.organizerName = $scope.details.organizerName;
						$scope.organizerEmail = $scope.details.organizerEmail;

						convertProposalsForDisplay(true);
						if ($stateParams.data && $stateParams.data.proposal) {
							acceptProposal($stateParams.data.proposal);
						}

						$scope.isRecurrenceCompatible = isRecurrenceCompatible;
						$scope.canEdit = canEdit;
						$scope.canEditAttachments = canEditAttachments;
						$scope.canEditRecurrence = canEditRecurrence;
						$scope.hasProposals = hasProposals;
						$scope.hideResource = hideResource;
						$scope.isOrganizer = isOrganizer;
						$scope.isMeeting = isMeeting;
						$scope.isMeetingEditable = isMeetingEditable;
						$scope.isMeetingDeletable = isMeetingDeletable;
						$scope.daysInMonth = daysInMonth;

						$scope.disallowCounters = $scope.details.disallowCounters;
						$scope.allowReplies = $scope.details.allowReplies;

						$scope.calendars = $scope.canEdit()
							? $.grep($scope.calendars, function (cal) { return cal.permission >= 8; })
							: $.grep(calendars, function (cal) { return cal.owner === owner && cal.id === calId; });

						if (!$scope.isNew)
							$scope.recurrenceDescription = [generateRecurrenceDescription().join(', ')];

						checkForConflicts();

						$scope.isInitialized = true;
						$rootScope.$broadcast("masonry:contentsChanged");
					}
				}
			}

			vm.loadSeries = function () {
				$scope.start = null;	// Without setting these to null, the series end date
				$scope.end = null;		// is changed from original to same day as $scope.start
				onSourcesLoaded();
			}
			vm.attachmentGuid = coreData.generateGuid();

			vm.filesUploading = 0;

		}

		function destroy() {
		}

		function generateRecurrenceDescription() {
			if ($scope.isNew)
				return [];
			let baseOpts = {
				start: $scope.details.start,
				end: $scope.details.end,
				isRecurring: $scope.isRecurring,
			};
			let meetingInfoOpts = !$scope.details ? {} : {
				recurrenceID: $scope.details.recurrenceID,
				isAllDay: $scope.details.allDay,
			};
			let recurrenceOpts = !$scope.details || !$scope.details.recurrence ? {} : {
				untilDateEnabled: $scope.details.recurrence.untilDateEnabled,
				untilDate: $scope.details.recurrence.untilDate,
				untilCountEnabled: $scope.details.recurrence.untilCountEnabled,
				untilCount: $scope.details.recurrence.untilCount,
				recurrenceType: $scope.details.recurrence.type,
				dailyInterval: ($scope.details.recurrence.dailyFreqDays || $scope.details.recurrence.dailyInterval),
				isWeekdaysOnly: $scope.details.recurrence.isWeekdaysOnly,
				weeklyInterval: $scope.details.recurrence.weeklyInterval,
				monthlyInterval: $scope.details.recurrence.monthlyInterval,
				monthlySpecificDay: $scope.details.recurrence.monthlySpecificDay,
				monthDay: $scope.details.recurrence.monthDay,
				monthlyPeriodIndex: $scope.details.recurrence.monthlyPeriodIndex,
				monthlyPeriodDayIndex: $scope.details.recurrence.monthlyPeriodDayIndex,
				yearlyInterval: $scope.details.recurrence.yearlyInterval,
				yearlySpecificDate: $scope.details.recurrence.yearlySpecificDate,
				yearlyDay: $scope.details.recurrence.yearlyDay,
				yearlyMonthIndex: $scope.details.recurrence.yearlyMonthIndex,
				yearlyPeriodIndex: $scope.details.recurrence.yearlyPeriodIndex,
				yearlyPeriodDayIndex: $scope.details.recurrence.yearlyPeriodDayIndex,
				weeklyDays: $scope.details.recurrence.weeklyDays
			};
			let opts = Object.assign({}, baseOpts, meetingInfoOpts, recurrenceOpts);
			return calendarRecurrenceDescriptionService.recurrenceDescriptionParts(opts);
		}

		function getAssociatedCalendar() {
			var result = $.grep($scope.calendars, function (element) { return $scope.owner === element.owner && $scope.calId === element.id; });
			if (result.length > 0)
				return result[0];

			result = $.grep($scope.calendars, function (element) { return element.isPrimary; });
			if (result.length > 0)
				return result[0];

			return $scope.calendars[0];
		}

		//#region Option factories

		function generateReminderOptions() {
			var reminderOptions = [], id;

			// None
			reminderOptions.push({ id: 0, name: $translate.instant("NONE") });

			// Minutes
			id = 1;
			for (var numMinutes = 0; numMinutes <= 15; numMinutes += 5)
				reminderOptions.push({ id: id++, name: i18n.reactPluralize("REMINDER_MINUTES", { count: numMinutes }) });
			reminderOptions.push({ id: 5, name: i18n.reactPluralize("REMINDER_MINUTES", { count: 30 }) });

			// Hours
			id = 6;
			for (var numHours = 1; numHours <= 12; ++numHours)
				reminderOptions.push({ id: id++, name: i18n.reactPluralize("REMINDER_HOURS", { count: numHours }) });
			reminderOptions.push({ id: 18, name: i18n.reactPluralize("REMINDER_HOURS", { count: 24 }) });

			// Days
			id = 19;
			for (var numDays = 2; numDays <= 4; ++numDays)
				reminderOptions.push({ id: id++, name: i18n.reactPluralize("REMINDER_DAYS", { count: numDays }) });

			// Weeks
			reminderOptions.push({ id: 22, name: i18n.reactPluralize("REMINDER_WEEKS", { count: 1 }) });
			reminderOptions.push({ id: 23, name: i18n.reactPluralize("REMINDER_WEEKS", { count: 2 }) });

			return reminderOptions;
		}

		function generateAvailabilityOptions() {
			var availabilityOptions = [];
			availabilityOptions.push({ id: 0, name: $translate.instant("AVAILABILITY_FREE") });
			availabilityOptions.push({ id: 1, name: $translate.instant("AVAILABILITY_TENTATIVE") });
			availabilityOptions.push({ id: 2, name: $translate.instant("AVAILABILITY_BUSY") });
			availabilityOptions.push({ id: 3, name: $translate.instant("AVAILABILITY_OOF") });
			return availabilityOptions;
		}

		function generateRecurrenceOptions() {
			var recurrenceOptions = [];
			recurrenceOptions.push({ id: $scope.recurrenceType.ONCE, name: $translate.instant("CALENDAR_RECURRENCE_ONCE") });
			recurrenceOptions.push({ id: $scope.recurrenceType.DAILY, name: $translate.instant("DAILY") });
			recurrenceOptions.push({ id: $scope.recurrenceType.WEEKLY, name: $translate.instant("WEEKLY") });
			recurrenceOptions.push({ id: $scope.recurrenceType.MONTHLY, name: $translate.instant("MONTHLY") });
			recurrenceOptions.push({ id: $scope.recurrenceType.YEARLY, name: $translate.instant("CALENDAR_RECURRENCE_YEARLY") });
			return recurrenceOptions;
		}

		function generateDaysOfWeek() {
			var dow = [];
			dow.push({ id: 0, name: $translate.instant("SUNDAY") });
			dow.push({ id: 1, name: $translate.instant("MONDAY") });
			dow.push({ id: 2, name: $translate.instant("TUESDAY") });
			dow.push({ id: 3, name: $translate.instant("WEDNESDAY") });
			dow.push({ id: 4, name: $translate.instant("THURSDAY") });
			dow.push({ id: 5, name: $translate.instant("FRIDAY") });
			dow.push({ id: 6, name: $translate.instant("SATURDAY") });
			dow.push({ id: 7, name: $translate.instant("DAY") });
			dow.push({ id: 8, name: $translate.instant("CALENDAR_RECUR_WEEKDAY") });
			dow.push({ id: 9, name: $translate.instant("CALENDAR_RECUR_WEEKEND_DAY") });
			return dow;
		}

		function generateFrequencyPeriods() {
			var periods = [];
			periods.push({ id: 0, name: $translate.instant("CALENDAR_FIRST") });
			periods.push({ id: 1, name: $translate.instant("CALENDAR_SECOND") });
			periods.push({ id: 2, name: $translate.instant("CALENDAR_THIRD") });
			periods.push({ id: 3, name: $translate.instant("CALENDAR_FOURTH") });
			periods.push({ id: 4, name: $translate.instant("CALENDAR_LAST") });
			return periods;
		}

		function generateMonths() {
			var months = [];
			months.push({ id: 0, name: $translate.instant("MONTH_NAMES_JANUARY") });
			months.push({ id: 1, name: $translate.instant("MONTH_NAMES_FEBRUARY") });
			months.push({ id: 2, name: $translate.instant("MONTH_NAMES_MARCH") });
			months.push({ id: 3, name: $translate.instant("MONTH_NAMES_APRIL") });
			months.push({ id: 4, name: $translate.instant("MONTH_NAMES_MAY") });
			months.push({ id: 5, name: $translate.instant("MONTH_NAMES_JUNE") });
			months.push({ id: 6, name: $translate.instant("MONTH_NAMES_JULY") });
			months.push({ id: 7, name: $translate.instant("MONTH_NAMES_AUGUST") });
			months.push({ id: 8, name: $translate.instant("MONTH_NAMES_SEPTEMBER") });
			months.push({ id: 9, name: $translate.instant("MONTH_NAMES_OCTOBER") });
			months.push({ id: 10, name: $translate.instant("MONTH_NAMES_NOVEMBER") });
			months.push({ id: 11, name: $translate.instant("MONTH_NAMES_DECEMBER") });
			return months;
		}

		//#endregion

		function getAvailability(id) {
			var temp = $.grep($scope.availabilityOptions, function (option) { return id === option.id; });
			if (temp.length > 0)
				return temp[0];

			return $scope.availabilityOptions[1];
		}

		function getRecurrenceType(id) {
			var temp = $.grep($scope.recurrenceOptions, function (option) { return id === option.id; });
			if (temp.length > 0)
				return temp[0];

			return $scope.recurrenceOptions[0];
		}

		function getResource(id) {
			var temp = $.grep($scope.resources, function (resource) { return id === resource.id; });
			if (temp.length > 0)
				return temp[0];

			return $scope.resources[0];
		}

		function lowercaseCompare(strA, strB) {
			if (!strA && !strB) { return true; }
			if (!strA || !strB) { return false; }
			return strA.toLowerCase() === strB.toLowerCase();
		}

		function hideResource() {
			if ($scope.organizerEmail) {
				if ($scope.organizerEmail.indexOf("@") > -1)
					return !lowercaseCompare(coreData.user.domain, $scope.organizerEmail.split("@")[1]);
				else
					return !lowercaseCompare(coreData.user.domain, $scope.organizerEmail);
			}
			return false;
		}

		function canEdit(allowEditingReceivedMeetings) {
			allowEditingReceivedMeetings = allowEditingReceivedMeetings || false;
			if ($scope.details.id === null)
				return true;
			if ($scope.isWebCalendar)
				return false;

			return (isMeeting() && !allowEditingReceivedMeetings) ? 
				isMeetingEditable() : 
				$scope.permission >= 8;
		}
		function canEditAttachments() {
			return canEdit() && !$scope.editingOccurrence();
		}
		function canEditRecurrence(allowEditingReceivedMeetings) {
			if (!canEdit(allowEditingReceivedMeetings)) return false;
			if ($location.search().instanceId && !vm.editSeries) return false;
			return true;
		}

		$scope.editingOccurrence = function () {
			return $location.search().instanceId && !vm.editSeries;
		}
		function trimTzDatafromDate(dateString) {
			return dateString.replace(/([-+]+\d+\:\d+)?Z?$/, ""); // removes datetime offset '-24:' or Z at end of string
		}
		function adjustEventDtToBrowserTime(appointmentDt, isAllDay) {
			if (isAllDay) {
				return moment(trimTzDatafromDate(appointmentDt.dt)).toDate();
			}
			var offset = moment.tz(appointmentDt.dt, appointmentDt.tz).utcOffset() - moment().utcOffset();
			return moment.tz(appointmentDt.dt, appointmentDt.tz).add(offset, "m").utc().toDate();

		}

		$scope.occurrenceStart = function () {
			if (vm.originalDetails.firstInstanceStart) {
				return adjustEventDtToBrowserTime(vm.originalDetails.firstInstanceStart, $scope.allDay);
			}
			return $scope.start;
		}

		$scope.occurrenceEnd = function () {
			if (vm.originalDetails.firstInstanceEnd) {
				return adjustEventDtToBrowserTime(vm.originalDetails.firstInstanceEnd, $scope.allDay);
			}
			return $scope.end;
		}

		function hasProposals() {
			return vm.originalDetails.proposals && vm.originalDetails.proposals.length;
		}

		function isOwner() {
			return lowercaseCompare($scope.owner, coreData.user.username);
		}

		function isOrganizer(organizerEmail, attendeeEmail) {
			return lowercaseCompare(organizerEmail, attendeeEmail);
		}

		function isRecurrenceCompatible() {
			if ($scope.currentRecurrence.id === 0) return true;
			var recurrence = $scope.details.recurrence;
			if (recurrence.type === 6 && recurrence.monthlyPeriodDayIndex < 0) return false;
			if (recurrence.type === 7 && recurrence.yearlyPeriodDayIndex < 0) return false;
			return true;
		}

		function shouldShowEmailNotification() {
			if (!$scope.isInitialized) return false;
			if ($scope.currentReminder.id === 0) return false;
			if ($scope.currentCalendar.isDomainResource) return false;
			if ($scope.currentCalendar.isSharedItem) return false;
			if ($scope.permission < 8) return false;
			if (!isMeeting()) return true;
			else if (!lowercaseCompare($scope.organizerEmail, coreData.user.emailAddress)) return false;
			return true;
		}

		function isMeeting() {
			return $scope.eventInfo.info.meetingStatus >= $scope.meetingType.IsAMeeting;
		}

		function isMeetingEditable() {
			return $scope.eventInfo.info.meetingStatus === $scope.meetingType.IsAMeeting && $scope.permission >= 8;

		}

		function isMeetingDeletable() {
			return $scope.eventInfo.info.meetingStatus >= $scope.meetingType.IsAMeeting &&
				!($scope.eventInfo.info.isTentative && $scope.details.status !== $scope.eventStatus.Cancelled) &&
				$scope.permission >= 8;
		}

		//#endregion

		//#region Attendee functions
		function querySearchResources(query) {
			var addedResourceNames = $scope.resources.map(res => res.name.toLowerCase());
			var resources = coreDataCalendar.getResources().reduce(function (result, res) {
				if (!addedResourceNames.includes(res.name.toLowerCase()))
					result.push(res);
				return result;
			}, []);

			var results = query ?
				resources.filter(resource => new RegExp(query.replace(/\*/g, '.*'), 'i').test(resource.name)) :
				resources;
			return results;
		}
		function querySearch(query) {
			if (query.indexOf(";") > -1)
				query = query.split(";").pop().trim();
			if (query.indexOf(",") > -1)
				query = query.split(",").pop().trim();

			var defer = $q.defer();

			var params = {
				search: query,
				requestLimit: 200,
			};

			$http
				.post("~/api/v1/settings/auto-complete-list-search/", params)
				.then(onSuccess, onFailure);

			return defer.promise;

			function onSuccess(success) {
				var results = success.data.emailData;
				var subset = {};
				var regex = /^[mM][aA][iI][lL][tT][oO]:/g;
				angular.forEach(results || [], function (value) {
					value.emailAddress = value.emailAddress.replace(regex, "");

					var existing = subset[value.emailAddress];
					if (!existing) {
						subset[value.emailAddress] = value;
						return;
					}

					if (value.source === "CONTACT" && existing.source !== "CONTACT") {
						// Contact list gets priority
						subset[value.emailAddress] = value;
					} else if (value.source === "GAL" && existing.source === "SENT_ITEM") {
						// GAL gets next priority
						subset[value.emailAddress] = value;
					} else {
						// Sent items can only fill in display name if one isn't set
						if (existing.emailAddress === existing.displayAs && value.displayAs && value.displayAs !== value.emailAddress) {
							existing.displayAs = value.displayAs;
							existing.formatted = "\"" + existing.displayAs + "\" <" + existing.emailAddress + ">";
						}
					}
				});

				var newResults = [];
				angular.forEach(subset, function (value) { newResults.push(value); });
				defer.resolve(newResults);
			}

			function onFailure() {
				defer.resolve([]);
			}
		}
		$scope.$watch("resourceItem", function () {
			if (!$scope.resourceItem || $scope.resourceItem === "") {
				return;
			}
			var keyEvent = { which: 13 };
			addResource(keyEvent);
		});

		function addResource(keyEvent) {
			if (keyEvent.which !== 13 && keyEvent.which !== 9)
				return;
			if ($scope.resourceItem) {
				var newResource = {
					isResource: true,
					name: $scope.resourceItem.name,
					email: $scope.resourceItem.emailAlias + "@" + coreData.user.domain,
					resourceType: $scope.resourceItem.sharedResourceType,
					status: 0,
					conflict: false,
					nonavail: null
				};
				addAttendeeInternal(keyEvent, newResource, true);
			}
			$scope.resourceItem = null;
			$scope.resourceField = "";

		}

		$scope.$watch("attendeeItem", function () {
			if (!$scope.attendeeItem || $scope.attendeeItem === "") {
				return;
			}
			var keyEvent = { which: 13 };
			addAttendee(keyEvent);
		});

		$scope.attendeesChanged = false;
		function addAttendee(keyEvent) {
			if (keyEvent.which !== 13 && keyEvent.which !== 9)
				return;

			var contacts;
			var ownerName;
			var newAttendee = {
				isResource: false,
				resourceType: 0,
				status: 0,
				conflict: false,
				nonavail: null
			};
			if ($scope.attendeeItem !== null && $scope.attendeeItem !== "") {
				if ($scope.attendeeItem.source === "CONTACT_GROUP") {
					$http.get(`~/api/v1/contacts/unpack-group/${$scope.attendeeItem.id}/${$scope.attendeeItem.sourceAccount}`)
						.then(
							function (success) {
								for (let i = 0; i < success.data.emailData.length; i++) {
									const member = success.data.emailData[i];
									if (member.emailAddress) {
										addAttendeeInternal(keyEvent, { name: member.displayAs, email: member.emailAddress }, false);

									}
								}
							},
							errorHandling.report);

					$scope.attendItem = null;
					$scope.attendeeField = "";
					return;
				} else {
					newAttendee.name = $scope.attendeeItem.displayAs;
					newAttendee.email = $scope.attendeeItem.emailAddress;
				}
			} else {
				if (!emailValidationService.isValidEmail($scope.attendeeField) || $scope.attendeeField === "") {
					errorHandling.warn("INVALID_EMAIL_ADDRESS");
					if (keyEvent.stopPropagation)
						keyEvent.stopPropagation();
					return;
				}

				var name = null;
				contacts = coreDataContacts.getContacts();
				ownerName = $.grep(contacts, function (du) { return lowercaseCompare(du.emailAddress, $scope.attendeeField); });
				if (ownerName.length > 0)
					name = ownerName[0].fullName;
				newAttendee.name = name;
				newAttendee.email = $scope.attendeeField;
			}

			$scope.attendItem = null;
			$scope.attendeeField = "";
			addAttendeeInternal(keyEvent, newAttendee, true);

		}
		function addAttendeeInternal(keyEvent, attendee, alertAlreadyInvited) {
			var found = attendee.isResource ?
				$scope.resources.some(att => lowercaseCompare(att.email, attendee.email)) :
				$scope.attendees.some(att => lowercaseCompare(att.email, attendee.email));

			if (found) {
				if (alertAlreadyInvited)
					errorHandling.warn(attendee.isResource ? "CALENDAR_ALREADY_INVITED" : "CALENDAR_ALREADY_ADDED_RESOURCE");
				if (keyEvent.stopPropagation)
					keyEvent.stopPropagation();
				return;
			}

			if (!lowercaseCompare($scope.currentCalendar.owner, coreData.user.domain) &&
				lowercaseCompare(attendee.email, $scope.currentCalendar.owner + "@" + coreData.user.domain)) {
				if (alertAlreadyInvited)
					errorHandling.warn("CALENDAR_CANNOT_INVITE_SELF");
				if (keyEvent.stopPropagation)
					keyEvent.stopPropagation();
				return;
			}
			if (attendee.isResource) {
				handleResourceAddAndLocation(attendee);
				$scope.resources.push(attendee);
			} else {
				$scope.attendees.push(attendee);
			}

			$scope.attendeesChanged = true;

			if ($scope.organizerName === "") {
				if ($scope.currentCalendar.isSharedItem) {
					if ($scope.currentCalendar.sharedResourceType === 0) {
						$scope.organizerName = coreData.user.displayName;
					} else {
						$scope.organizerName = $scope.currentCalendar.owner + "@" + coreData.user.domain;
					}
				} else if (lowercaseCompare($scope.currentCalendar.owner, coreData.user.username)) {
					$scope.organizerName = coreData.user.displayName;
				}
			} else {
				var contacts = coreDataContacts.getContacts();
				var ownerName = $.grep(contacts,
					function (du) { return lowercaseCompare(du.userName, $scope.currentCalendar.owner); });
				if (ownerName.length > 0) {
					$scope.organizerName = ownerName[0].fullName;
				}
			}

			if ($scope.organizerEmail === "") {
				if (!$scope.currentCalendar.isSharedItem) {
					$scope.organizerEmail = coreData.user.emailAddress;
					if ($scope.organizerEmail.indexOf("@") === 0 && !lowercaseCompare($scope.currentCalendar.owner, coreData.user.domain))
						$scope.organizerEmail += "@" + coreData.user.domain;
				} else {
					if ($scope.currentCalendar.sharedResourceType === 0) {
						$scope.organizerEmail = userDataService.user.emailAddress;
					} else {
						$scope.organizerEmail = $scope.currentCalendar.owner + orgemail.substring(orgemail.indexOf("@"));
					}
				}
			}

			onValueChanged();
			checkForConflicts();
		}

		vm.conferenceRoomDialog = undefined;
		function handleResourceAddAndLocation(resource) {
			if (resource.resourceType !== 2)
				return;

			if ($scope.location !== undefined) {
				var roomsOnly = $.grep($scope.resources, function (res) { return res.resourceType === 2; });
				var addedResourceNames = roomsOnly.map(res => res.name.toLowerCase());
				var locationSplits = $scope.location.split(';');
				var nonResourceFound = false;
				for (var index = 0; index < locationSplits.length; index++) {
					var res = locationSplits[index].trim().toLowerCase();
					if (res.length === 0)
						continue;
					if (vm.conferenceRoomDialog === undefined && res === resource.name.toLowerCase())
						return;
					if (!addedResourceNames.includes(res))
						nonResourceFound = true;
				}

				if (nonResourceFound) {
					// If we get here then we have locations that are not resources so we will not modify the text
					if (vm.conferenceRoomDialog === undefined) {
						vm.conferenceRoomDialog = $mdDialog.confirm({
							title: $translate.instant('CONFIRMATION_REQUIRED'),
							textContent: $translate.instant('CONFERENCE_ROOM_UPDATE_CONFIRMATION_CONTENT'),
							ok: $translate.instant('OK'),
							cancel: $translate.instant('CANCEL'),
						});

						$mdDialog.show(vm.conferenceRoomDialog).then(function () {
							addConfRoomToLoaction(true, undefined);
							vm.conferenceRoomDialog = undefined;
						}, function () {
							vm.conferenceRoomDialog = undefined;
						});
					}
				} else {
					addConfRoomToLoaction(false, resource.name);
				}
			} else {
				addConfRoomToLoaction(false, resource.name);
			}
		}

		function addConfRoomToLoaction(reset, name) {
			if (reset) {
				$scope.location = "";
				var roomsOnly = $.grep($scope.resources, function (res) { return res.resourceType === 2; });
				angular.forEach(roomsOnly.map(x => x.name), function (val) { addConfRoomToLoaction(false, val) });
			}
			else if ($scope.location === undefined || $scope.location.length === 0) {
				$scope.location = name;
			} else {
				$scope.location += '; ' + name
			}
		}

		function handleResourceRemoveAndLocation(resource) {
			if (resource.resourceType !== 2)
				return;

			if ($scope.location !== undefined && $scope.location !== '') {
				var roomsOnly = $.grep($scope.resources, function (res) { return res.resourceType === 2; });
				var addedResourceNames = roomsOnly.map(res => res.name.toLowerCase());
				var locationSplits = $scope.location.split(';');
				var nonResourceFound = false;
				for (var index = 0; index < locationSplits.length; index++) {
					var res = locationSplits[index].trim().toLowerCase();
					if (!addedResourceNames.includes(res))
						nonResourceFound = true;
				}

				if (nonResourceFound) {
					// If we get here then we have locations that are not resources so we will not modify the text
					return;
				}

				$scope.location = "";
				for (var index2 = 0; index2 < locationSplits.length; index2++) {
					var res2 = locationSplits[index2].trim();
					if (res2 !== resource.name) {
						if ($scope.location.length === 0)
							$scope.location += res2;
						else
							$scope.location += "; " + res2;
					}
				}
				$scope.location.trim(';');
			}
		}

		function removeResource(resource, form) {
			var index = $scope.resources.indexOf(resource);
			if (index > -1) {
				// This method call needs to happen before we splice the resource from $scope.resources
				handleResourceRemoveAndLocation(resource);
				$scope.resources.splice(index, 1);
				$scope.attendeesChanged = true;
				$scope.onValueChanged(form);
				checkForConflicts();
				vm.eventForm.$setDirty();
			}
			index = $scope.eventInfo.info.attendees.findIndex(att => att.email === resource.email);
			if (index > -1)
				$scope.eventInfo.info.attendees.splice(index, 1);
		}
		function removeAttendee(attendee, form) {
			var index = $scope.attendees.indexOf(attendee);
			if (index > -1) {
				$scope.attendees.splice(index, 1);
				$scope.attendeesChanged = true;
				$scope.onValueChanged(form);
				checkForConflicts();
				vm.eventForm.$setDirty();
			}
			index = $scope.eventInfo.info.attendees.findIndex(att => att.email === attendee.email);
			if (index > -1)
				$scope.eventInfo.info.attendees.splice(index, 1);
		}

		function getUserAvailability(date) {
			var participants = $.map($scope.attendees, function (attendee) { return attendee.email; });
			if (participants.length === 0)
				return;

			var params = {
				date: date.toISOString(),
				mailAddresses: participants,
				uidToIgnore: (vm.id !== "new" && vm.id !== null) ? vm.id : null
			};
			$http
				.post("~/api/v1/calendars/user-availability", JSON.stringify(params))
				.then(onUserAvailabilitySuccess, errorHandling.report);

			function onUserAvailabilitySuccess(success) {
				angular.forEach(success.data, function (attendee) {
					var temp = $.grep($scope.attendees, function (att) { return att.email === attendee.emailAddress; });
					if (temp.length > 0) {
						temp[0].nonavail = attendee.busyHours;
					}
				});
			}
		}

		$scope.availabilityDate = $scope.start;
		$scope.availStartHour = Math.max(0, moment($scope.start).hour() - 1);
		getUserAvailability($scope.availabilityDate);

		function changeAvailHour(amount) {
			$scope.availStartHour += amount;
			if ($scope.availStartHour < 0)
				$scope.availStartHour = 0;
			else if ($scope.availStartHour > 20)
				$scope.availStartHour = 20;
		}

		function changeAvailDate(amount) {
			$scope.availabilityDate = moment($scope.availabilityDate).add(amount, "d").toDate();
			getUserAvailability($scope.availabilityDate);
		}

		function getAvailHour(offset) {
			var d = moment($scope.availabilityDate);
			var date = moment([d.year(), d.month(), d.date()]);
			return date.add($scope.availStartHour + offset, "h").toDate();
		}

		function isUnavailable(attendee, offset) {
			if (attendee == undefined || attendee.nonavail === null)
				return null;

			var utcOffset = moment().utcOffset();
			var totalOffset = $scope.availStartHour + offset - (utcOffset / 60);
			if (totalOffset < 0)
				totalOffset += 24;
			else if (totalOffset > 23)
				totalOffset = totalOffset % 24;
			return attendee.nonavail[totalOffset];
		}

		function removeAutocompleteEntry(item, ev, scope) {
			ev.preventDefault();
			ev.stopPropagation();
			scope.$parent.attendeeField = "";
			onDoRemove();

			function onDoRemove() {
				var params = JSON.stringify({
					username: coreData.user.username,
					idsToDelete: [item.id]
				});

				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/auto-complete-delete", params)
					.then(onDeleteSuccess, errorHandling.report)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() { }
			}

			return false;
		}

		function removeAttachedFile(attachment, ev) {
			if (!canEdit() && !attachment) return;
			if (attachment.isNew && typeof attachment.remove === "function") {
				attachment.remove();
				return;
			}
			vm.removedAttachedFiles.push(attachment.fileReference);
			if (vm.eventForm)
				vm.eventForm.$setDirty();
		}


		//#endregion

		//#region Determine changed values
		$scope.$watch("recurUntilDate", function (newValue, oldValue) {
			if (!newValue || !oldValue || newValue === oldValue) return;
			$scope.onRecurrenceChanged();
		});

		function participantStatusToString() {
			switch ($scope.participantStatus) {
				case 0:
					return "NEEDS-ACTION";
					break;
				case 1:
					return "ACCEPTED";
					break;
				case 2:
					return "TENTATIVE";
					break;
				case 3:
					return "DECLINED";
					break;
				default:
					return "NEEDS-ACTION";
			}
		}

		function getChangedValues() {
			var result = [];
			// Event info
			var calId = $scope.currentCalendar.id;
			if ($scope.calId !== calId || $scope.owner !== $scope.currentCalendar.owner) {
				result.push({ calId: calId });
				result.push({ owner: $scope.currentCalendar.owner });
			}
			if ($scope.details.subject !== $scope.subject) result.push({ subject: $scope.subject });
			if ($scope.details.location !== $scope.location) result.push({ location: $scope.location });
			if ($scope.details.start.tz !== $scope.timeZoneStart.location || moment.tz($scope.details.start.dt, $scope.details.start.tz).format() !== moment($scope.start).format()) result.push({ start: $scope.start });
			if ($scope.details.end.tz !== $scope.timeZoneEnd.location || moment.tz($scope.details.end.dt, $scope.details.end.tz).format() !== moment($scope.end).format()) result.push({ end: $scope.end });
			if ($scope.details.allDay !== $scope.allDay) result.push({ allDay: $scope.allDay });
			if ($scope.details.availability !== $scope.currentAvailability.id) result.push({ availability: $scope.currentAvailability.id });
			if ($scope.details.reminderIndex !== $scope.currentReminder.id) result.push({ reminderIndex: $scope.currentReminder.id });
			if ($scope.details.emailNotificationEnabled !== $scope.emailNotificationEnabled) result.push({ emailNotificationEnabled: $scope.currentCalendar.isDomainResource ? false : $scope.emailNotificationEnabled });
			if ($scope.details.emailNotification !== $scope.emailNotification) result.push({ emailNotification: $scope.emailNotification });
			if ($scope.details.participantStatus !== participantStatusToString()) result.push({ participantStatus: $scope.participantStatus });


			var description = $scope.editorScope.getHtml();
			if ($scope.details.description !== description) result.push({ description: description });

			if ($scope.details.isPrivate !== $scope.privateEvent) result.push({ isPrivate: $scope.privateEvent });
			if ($scope.details.organizerName !== $scope.organizerName) result.push({ organizerName: $scope.organizerName });
			if ($scope.details.organizerEmail !== $scope.organizerEmail) result.push({ organizerEmail: $scope.organizerEmail });
			if (vm.attachedFiles && vm.attachedFiles.some(x => !x.isNew) && vm.removedAttachedFiles) {
				result.push({ removedFiles: vm.removedAttachedFiles });
			}
			// Event attendees
			if ($scope.attendeeField !== "") {
				var atts = $scope.attendeeField.split(/\s*[;,]\s*/);	// split on semicolons and commas
				for (var i = 0, len = atts.length; i < len; ++i) {
					if (!emailValidationService.isValidEmail(atts[i]))
						continue;
					if (lowercaseCompare(atts[i], coreData.user.emailAddress))
						continue;

					var newAttendee = {
						name: null,
						email: atts[i],
						status: 0,
						conflict: false,
						nonavail: null
					};

					$scope.attendees.push(newAttendee);
					$scope.attendeesChanged = true;
				}
			}
			if (!$scope.attendeesChanged && $scope.details.resourceId && $scope.resources.some(res => res.resourceType === 2))
				$scope.attendeesChanged = true;
			if ($scope.attendeesChanged) {
				result.push({ attendees: $scope.attendees.concat($scope.resources) });
			}

			// Event categories
			if ($scope.categoriesChanged) result.push({ categories: $scope.categories.filter(cat => cat.selected) });
			// Event recurrence
			if (!$location.search().instanceId && $scope.recurrenceChanged && isRecurrenceCompatible()) {
				var recurrence = angular.copy(vm.originalDetails.recurrence);

				switch (vm.recurUntilOption) {
					case 0:	// Forever
					case "0":
						recurrence.untilCountEnabled = false;
						recurrence.untilDateEnabled = false;
						break;

					case 1:	// Interval
					case "1":
						recurrence.untilCount = vm.recurUntilCount;
						recurrence.untilCountEnabled = true;
						recurrence.untilDateEnabled = false;

						break;

					case 2:	// Date
					case "2":
						recurrence.untilDate = moment($scope.recurUntilDate).startOf("day").add(1, "d").add(-1, "s").utc().toDate();
						recurrence.untilDateEnabled = true;
						recurrence.untilCountEnabled = false;
						break;
				}
				recurrence.type = $scope.currentRecurrence.id;
				switch (recurrence.type) {
					default:
					case 0:	// Once
						break;

					case 4:	// Daily
						if (vm.dailyFreq === 0) {
							recurrence.dailyInterval = vm.dailyFreqDays;
							recurrence.isWeekdaysOnly = false;
						}
						else
							recurrence.isWeekdaysOnly = true;
						break;

					case 5:	// Weekly
						recurrence.weeklyInterval = vm.weeklyFreqWeeks;
						recurrence.weeklyDays = vm.weeklyDays;
						break;

					case 6:	// Monthly
						recurrence.monthlySpecificDay = $scope.monthlyFreq === 0;
						recurrence.monthlyInterval = $scope.monthlyFreqMonths;
						if (recurrence.monthlySpecificDay === true) {
							recurrence.monthDay = $scope.monthlyFreqDay;
						} else {
							recurrence.monthlyPeriodIndex = $scope.monthlyFreqPeriod.id;
							recurrence.monthlyPeriodDayIndex = $scope.monthlyFreqPeriodDay.id;
						}
						break;

					case 7:	// Yearly
						recurrence.yearlySpecificDate = $scope.yearlyFreq === 0;
						recurrence.yearlyInterval = $scope.yearlyFreqYears;
						if (recurrence.yearlySpecificDate === true) {
							recurrence.yearlyDay = vm.yearlyFreqMonthDate;
							recurrence.yearlyMonthIndex = $scope.yearlyFreqMonth;
						} else {
							recurrence.yearlyPeriodIndex = $scope.yearlyFreqPeriod;
							recurrence.yearlyPeriodDayIndex = $scope.yearlyFreqPeriodDay;
							recurrence.yearlyMonthIndex = $scope.yearlyFreqPeriodMonth;
						}
						break;
				}

				result.push({ recurrence: recurrence });
			}
			if (vm.attachedFiles.some(x => x.isNew)) {
				result.push({ attachmentsAdded: true });
			}
			return result;
		};

		//#endregion

		//#region Events
		$scope.onRecurrenceTypeChanged = function (newVal) {
			if (typeof newVal !== 'undefined' && newVal !== null)
				$scope.currentRecurrence = newVal;

			if (!vm.eventForm || vm.eventForm.$pristine) return;
			var newWeeklyFreqDay = moment($scope.start).day();
			var newMonthFreqDay = moment($scope.start).date();
			var newYearlyFreqMonth = $scope.months[moment($scope.start).month()].id;
			var newYearlyFreqMonthDate = moment($scope.start).date();
			var newRecurUntilDate = moment($scope.start).add(1, "y").startOf("day").add(1, "d").add(-1, "s").utc().toDate();

			if (vm.recurUntilOption === 2) {
				if (newRecurUntilDate > $scope.recurUntilDate) {
					$scope.recurUntilDate = newRecurUntilDate;
					$scope.recurrenceChanged = true;
				}
			} else {
				$scope.recurUntilDate = newRecurUntilDate;
			}

			if ($scope.currentRecurrence.id === 5) {
				$scope.calEvCtrl.weeklyDays = [false, false, false, false, false, false, false];
				$scope.calEvCtrl.weeklyDays[newWeeklyFreqDay] = true;
				$scope.recurrenceChanged = true;
			}

			if ($scope.monthlyFreqDay !== newMonthFreqDay) {
				$scope.monthlyFreqDay = newMonthFreqDay;
				$scope.recurrenceChanged = true;
			}
			if ($scope.yearlyFreqMonth !== newYearlyFreqMonth) {
				$scope.yearlyFreqMonth = newYearlyFreqMonth;
				$scope.recurrenceChanged = true;
			}
			if (vm.yearlyFreqMonthDate !== newYearlyFreqMonthDate) {
				vm.yearlyFreqMonthDate = newYearlyFreqMonthDate;
				$scope.recurrenceChanged = true;
			}
		};

		$scope.infoChanged = false;
		function onValueChanged(form) {
			$scope.infoChanged = true;
			if (form && typeof form.$setDirty === 'function')
				form.$setDirty();
		}

		function allDayChanged() {
			if (!vm.availabilitySet) {
				if ($scope.allDay === true) {
					$scope.currentAvailability = _.find($scope.availabilityOptions, function (opt) { return opt.id === 0 });
					$scope.currentReminder = _.find($scope.reminderOptions, function (opt) { return opt.id === 0 });
					vm.showTimeZones = false;
				} else {
					$scope.currentAvailability = _.find($scope.availabilityOptions, function (opt) { return opt.id === 2 });
					$scope.currentReminder = _.find($scope.reminderOptions, function (opt) { return opt.id === 2 });
				}
			}
		}

		$scope.onKeyDown = function (ev) {
			//backspace
			if (ev != null && ev.keyCode === 8) {
				onValueChanged();
			}
		}

		$scope.$watch("currentCalendar", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				if (newValue.isDomainResource)
					$scope.afContext = "domainshare";
				else {
					$scope.afContext = "user";
					apiCategories.categoryOwner = `${newValue.owner}@${userDataService.user.domain}`;
					onCategoryListModified();
				}
				onValueChanged();
				checkForConflicts();
			}
		});

		$scope.$watch("currentAvailability", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				onValueChanged();
			}
		});

		$scope.$watch("currentReminder", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				onValueChanged();
			}
		});

		$scope.onRecurrenceChanged = function (recurrence) {
			if (recurrence != undefined && $scope.currentRecurrence !== recurrence)
				$scope.currentRecurrence = recurrence;
			$scope.recurrenceChanged = true;
			onValueChanged();
		};

		$scope.onParticipantStatusChanged = function (status) {
			if (status != undefined && $scope.participantStatus !== status)
				$scope.participantStatus = status
			onValueChanged();
		}

		$scope.$watch("currentRecurrence", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
				checkForConflicts();
			}
		});

		$scope.$watch("monthlyFreqDay", adjustStartEndByRecurrence);
		$scope.$watch(function () { return vm.yearlyFreqMonthDate; }, adjustStartEndByRecurrence);

		function adjustStartEndByRecurrence(newValue, oldValue) {
			if (vm.editSeries && newValue !== oldValue && $scope.isRecurring && (($scope.currentRecurrence.id === 6 && $scope.monthlyFreq === 0) || ($scope.currentRecurrence.id === 7 && $scope.yearlyFreq === 0))) {
				if (!newValue) return;
				if (newValue < 1) return;
				var start = moment($scope.start);
				var end = moment($scope.end);
				var duration = moment.duration(end.diff(start));

				switch (start.month() + 1) {
					case 1: case 3: case 5: case 7: case 8: case 10: case 12:
						if (newValue > 31) return;
						break;
					case 4: case 6: case 9: case 11:
						if (newValue > 30) return;
						break;
					case 2:
						if ((start.isLeapYear() && newValue > 29) || newValue > 28) return;
						break;
					default:
						return;
				}

				start.date(newValue);
				end = moment(start).add(duration);
				$scope.start = start.toDate();
				$scope.end = end.toDate();
			}
		}

        $scope.onWeeklyDaysChanged = function () {
			$scope.recurrenceChanged = true;
			onValueChanged();
			debouncedAdjustStartEndForWeeklyDays();
		}		
        
		const debouncedAdjustStartEndForWeeklyDays = _.debounce(adjustStartEndForWeeklyDays, 750);
		function adjustStartEndForWeeklyDays(){
			var startDay = $scope.start.getDay();
			if (vm.weeklyDays[startDay])
				return;
			
			for (let increment = 1; increment < 7; increment++)
			{
				let checkDay = (startDay + increment) % 7;
				if (vm.weeklyDays[checkDay])
				{
					let tempDate = new Date($scope.start);
					tempDate.setDate($scope.start.getDate() + increment);
					$scope.start = tempDate;
					$scope.$applyAsync();
					break;
				}
			}
		}

		$scope.$watchCollection(function () { return vm.weeklyDays; }, function (newValue, oldValue) {
			if (newValue === undefined && oldValue === undefined) return;

			$scope.weeklyDayStr = "";
			if (!newValue) return;

			var selectedDays = [];
			var i;
			for (i = 0; i < newValue.length; ++i) {
				if (newValue[i])
					selectedDays.push($scope.daysOfWeek[i].name);
			}

			if (selectedDays.length === 1) {
				$scope.weeklyDayStr = selectedDays[0];
			} else if (selectedDays.length === 2) {
				$scope.weeklyDayStr = selectedDays[0] + " " + $filter("translate")("AND").toLowerCase() + " " + selectedDays[1];
			} else if (selectedDays.length > 2) {
				for (i = 0; i < selectedDays.length - 1; ++i) {
					$scope.weeklyDayStr += selectedDays[i] + ", ";
				}
				$scope.weeklyDayStr += $filter("translate")("AND").toLowerCase() + " " + selectedDays[selectedDays.length - 1];
			}
		});

		$scope.$watch("allDay", function (newValue, oldValue) {
			if (newValue === undefined && oldValue === undefined) return;
			if (!$scope.attendees || $scope.attendees.length === 0) return;
			checkForConflicts();
		});

		//#endregion

		//#region Categories

		$scope.categoriesChanged = false;

		function onCategoryListModified() {
			try {
				const categories = apiCategories.getCategories();
				$scope.categories = setupCategories(categories, $.grep($scope.categories, function (cat) { return cat.selected; }));
			}
			catch (err) {
				errorHandling.report(err);
			}
		}

		function hasSelectedCategory() {
			if (!$scope.categories)
				return false;
			for (var i = 0; i < $scope.categories.length; i++)
				if ($scope.categories[i].selected) return true;
			return false;
		}

		function categoryColorByName(catName) {
			const cat = apiCategories.getCategoryByName(catName);
			if (!cat || cat.colorIndex == -1)
				return null;
			const color = apiCategories.getCategoryColor(cat.colorIndex);
			if (!color || !color.rgb)
				return null;
			return color.rgb;

		}

		function setupCategories(categories, selectedCategories) {
			// Merges categories
			categories.forEach(function (cat) { cat.selected = false; });

			if (selectedCategories) {
				categories = selectedCategories.reduce(function (res, cat) {
					const masterCat = res.find(c => c.name === cat.name);
					if (masterCat) {
						masterCat.selected = cat.selected;
					} else {
						res.push({ name: cat.name, colorIndex: -1, master: false, translatedName: $filter("translate")(cat.name), selected: true });
					}
					return res;
				},
					categories);

			}

			return categories;
		}

		function onCategoryChanged(cat) {
			if (cat)
				cat.selected = !cat.selected;
			$scope.categoriesChanged = true;
			vm.eventForm.$setDirty();
			onValueChanged();
		}

		function onClearAllCategories() {
			for (var i = 0; i < $scope.categories.length; i++)
				$scope.categories[i].selected = false;
			$scope.categoriesChanged = true;
			vm.eventForm.$setDirty();
			onValueChanged();
		}

		function openManageCategoriesModal(ev) {
			$mdDialog.show({
				controller: "manageCategoriesDialogController",
				controllerAs: "manageCategoriesCtrl",
				templateUrl: "app/shared/modals/manage.categories.dlg.html",
				targetEvent: ev
			});
		}

		function openProposeChangesModal(ev) {
			$mdDialog.show({
				locals: { eventInfo: $scope.eventInfo, date: $scope.isRecurring && $scope.instanceStart !== undefined ? $scope.instanceStart : $scope.start, id: vm.id, fromEmail: false, replyingComment: '' },
				controller: "calendarEventProposeController",
				controllerAs: "calendarEventProposeCtrl",
				templateUrl: "app/calendars/modals/calendar-event-propose.dlg.html",
				targetEvent: ev
			});
		}

		//#endregion

		//#region Recurrence period changes
		$scope.$watch("monthlyFreqPeriod", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});

		$scope.$watch("monthlyFreqPeriodDay", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});

		$scope.$watch("yearlyFreqMonth", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});

		$scope.$watch("yearlyFreqPeriod", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});

		$scope.$watch("yearlyFreqPeriodDay", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});

		$scope.$watch("yearlyFreqPeriodMonth", function (newValue, oldValue) {
			if (newValue && oldValue && newValue !== oldValue) {
				$scope.recurrenceChanged = true;
				onValueChanged();
			}
		});
		//#endregion

		//#region Validation

		$scope.isSubjectValid = true;

		$scope.isValid = true;
		function validate() {
			var valid = true;

			// Main info
			$scope.isSubjectValid = $scope.subject && $scope.subject.length > 0;
			valid &= $scope.isSubjectValid;
			if ($scope.subject === undefined || $scope.subject === null)
				valid &= false;
			valid &= validateDates();
			valid &= !$scope.emailNotificationEnabled || $scope.emailNotification.length > 0;

			// Recurrence
			valid &= validateRecurUntilCount();
			switch ($scope.currentRecurrence.id) {
				default:
				case 0:
					break;

				case 7:
					valid &= validateYearlyFreqYears();
					valid &= validateYearlyFreqMonthDate();
					break;

				case 6:
					valid &= validateMonthlyFreqMonths();
					valid &= validateMonthlyFreqDay();
					break;

				case 5:
					valid &= validateWeeklyFreqWeeks();
					break;

				case 4:
					valid &= validateDailyFreqDays();
					break;
			}

			return valid === 0 ? false : true;
		}

		function validateRecurUntilCount() {
			return parseInt(vm.recurUntilOption, 10) !== 1 || parseInt(vm.recurUntilCount, 10) > 0;
		}

		function validateDailyFreqDays() {
			return parseInt(vm.dailyFreq) !== 0 || parseInt(vm.dailyFreqDays) > 0;
		}

		function validateWeeklyFreqWeeks() {
			return parseInt(vm.weeklyFreqWeeks) > 0;
		}

		function validateMonthlyFreqMonths() {
			return parseInt($scope.monthlyFreqMonths) > 0;
		}

		function validateMonthlyFreqDay() {
			return parseInt($scope.monthlyFreq) !== 0 || (parseInt($scope.monthlyFreqDay) > 0 && parseInt($scope.monthlyFreqDay) <= 31);
		}

		function daysInMonth() {
			var month = $scope.yearlyFreqMonth + 1;
			switch (month) {
				case 2:
					return 29;

				case 4:
				case 6:
				case 9:
				case 11:
					return 30;

				default:
					return 31;
			}
		}

		function validateYearlyFreqYears() {
			return parseInt($scope.yearlyFreqYears) > 0;
		}

		function validateYearlyFreqMonthDate() {
			if (parseInt($scope.yearlyFreq) !== 0)
				return true;

			var date = parseInt(vm.yearlyFreqMonthDate);
			if (date <= 0)
				return false;

			var month = $scope.yearlyFreqMonth + 1;
			switch (month) {
				case 1:
				case 3:
				case 5:
				case 7:
				case 8:
				case 10:
				case 12:
					return date <= 31;

				case 2:
					return date <= 29;

				//case 2:
				case 4:
				case 6:
				case 9:
				case 11:
					return date <= 30;

				default:
					return false;
			}
		}

		function validateDates() {
			if (!angular.isDate($scope.start) || !angular.isDate($scope.end) || !angular.isDate($scope.recurUntilDate))
				return false;

			var startDate = getTimeZoneMoment($scope.start, $scope.timeZoneStart).toDate();
			var endDate = getTimeZoneMoment($scope.end, $scope.timeZoneEnd).toDate();
			if (endDate < startDate || ($scope.isRecurring && !$scope.editingOccurrence() && $scope.recurUntilDate < $scope.start))
				return false;

			return true;
		}

		$scope.$watch("subject", function (newValue, oldValue) {
			if (newValue === oldValue)
				return;
			if ($scope.subject && $scope.subject.length > 0) {
				$scope.isSubjectValid = true;
			} else {
				$scope.isSubjectValid = false;
			}
			onValueChanged();
		});

		//#endregion

		//#region Save, delete, cancel

		function duplicate(ev) {
			vm.originalDetails.id = undefined;
			save(ev);
		}

		function save(ev) {
			if (!($scope.isValid = validate())) {
				$scope.infoChanged = true;
				return;
			}
			var changes = getChangedValues();
			var owner = (vm.id !== "new" && vm.id !== null) ? (vm.owner || vm.originalDetails.owner) : $scope.currentCalendar.owner;
			var uid = vm.calId || $scope.currentCalendar.id;

			var current = moment();
			var start = moment.tz(moment($scope.start).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneStart.location);
			var end = moment.tz(moment($scope.end).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneEnd.location);
			var allDay = vm.originalDetails.allDay;
			for (var i in changes) {
				if (changes.hasOwnProperty(i)) {
					var change = changes[i];
					if (change.participantStatus != undefined) {
						switch (change.participantStatus) {
							case 1:
								acceptInvite();
								break;
							case 2:
								tentativelyAcceptInvite();
								break;
							case 3:
								declineInvite();
								break;
							default:
						}
					}
					if (change.allDay != undefined) {
						allDay = change.allDay;
						continue;
					}
					if (change.attendees != undefined) {
						// Check if user added self as an attendee when the selected
						// calendar was a shared calendar then was switched back to own calendar
						var index, orgEmail, orgName;
						var ownerEmail = (owner + "@" + userDataService.user.domain).toLowerCase();
						if (!lowercaseCompare(ownerEmail, userDataService.user.emailAddress))
							continue;

						var self = $.grep(change.attendees, function (element) { return lowercaseCompare(element.email, ownerEmail); });
						if (!self || self.length === 0)
							continue;

						index = change.attendees.indexOf(self[0]);
						if (index > -1)
							change.attendees.splice(index, 1);
						if (change.attendees.length > 0) {
							// If there are still attendees, ensure that self is the organizer
							orgEmail = $.grep(changes, function (element) { return element.organizerEmail; });
							orgName = $.grep(changes, function (element) { return element.organizerName; });
							if (orgEmail && orgEmail.length > 0 && !lowercaseCompare(orgEmail[0].organizerEmail, ownerEmail)) {
								orgEmail[0].organizerEmail = ownerEmail;
								if (orgName && orgName.length > 0)
									orgName[0].organizerName = userDataService.user.displayName;
							}
						}
					}
				}
			}
			if (allDay) {
				current.startOf("day");
				start.startOf("day");
				end.startOf("day");
			}
			var isSeries = $scope.isRecurring && vm.editSeries || $scope.eventInfo.isNew && change && change.recurrence;

			CheckEventHasPast(changes, isSeries, false)
				.then(function (data) {
					if (data) {
						var params = {
							controller: "calendarDateWarnDialogController",
							controllerAs: "ctrl",
							templateUrl: "app/calendars/modals/calendar-datewarn.dlg.html",
							targetEvent: ev,
							clickOutsideToClose: false,
							isMeeting: isMeetingEditable() || (changes.some(change => change.attendees)),
							isSeries: $scope.isRecurring && vm.editSeries,
							isRecurring: vm.editSeries,
							isNew: $scope.isNew
						};

						$mdDialog
							.show(params)
							.then(function (result) { onSuccess(result.notify) }, function () { });
					} else {
						onSuccess(true);
					}
				}, errorHandling.report);



			function onSuccess(notifyAttendees) {
				if (vm.hasMeeting) {
					if (vm.meetingRemoved)
						deleteMeeting().then(doEventSave, errorMessageService.showErrorMessage);
					else
						updateMeeting().then(doEventSave, errorMessageService.showErrorMessage);
				} else
					doEventSave();

				function doEventSave() {
					saveEvent(owner, uid, ((vm.id !== "new" && vm.id !== null) ? vm.id : null), vm.originalDetails, changes, notifyAttendees);
				}
			}
		}

		// returns a promise with a true if the event or series is in the past
		function CheckEventHasPast(changes, isSeries, forDelete) {
			if (!forDelete) {
				var current = moment();
				var start = moment.tz(moment($scope.start).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneStart.location);
				var end = moment.tz(moment($scope.end).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneEnd.location);
				return $q.when(start < current || end < current);
			}

			if (changes === undefined)
				changes = [];
			var defer = $q.defer();
			var change = changes.find(change => change.allDay);
			var allDay = change && change.allDay ? change.allDay : vm.originalDetails.allDay;
			change = changes.find(change => change.recurrence);
			var recurrence = change && change.recurrence ? change.recurrence : vm.originalDetails.recurrence;

			if (isSeries && recurrence) {
				var evt = {
					allDay: allDay,
					start: vm.originalDetails.start,
					end: vm.originalDetails.end,
					recurrence: recurrence

				};
				change = changes.find(change => change.start);
				if (change && change.start) {
					evt.start = {
						dt: moment.tz(moment(change.start), "UTC").add(moment(change.start).utcOffset(), "minutes"),
						tz: $scope.timeZoneStart.location
					};
				}
				change = changes.find(change => change.end);
				if (change && change.end) {
					evt.end = {
						dt: moment.tz(moment(change.end), "UTC").add(moment(change.end).utcOffset(), "minutes"),
						tz: $scope.timeZoneEnd.location
					};
				}
				$http.post("~/api/v1/calendars/event-past-checker", JSON.stringify(evt))
					.then(function (success) {
						defer.resolve(success.data);
					}, defer.reject);
			} else {
				var current = moment();
				var start = moment.tz(moment($scope.start).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneStart.location);
				var end = moment.tz(moment($scope.end).format("YYYY-MM-DDTHH:mm:ss"), $scope.timeZoneEnd.location);

				defer.resolve(start < current || end < current);
			}
			return defer.promise;
		}

		function saveEvent(owner, calId, eventId, originalDetails, changes, notifyAttendees) {
			var startChanged = false;
			var endChanged = false;
			var calSource = $scope.calendars.filter(cal => cal.id == calId)[0];
			var evt = $.extend(true, {}, originalDetails);
			var removedFiles = [];
			for (var i in changes) {
				if (!changes.hasOwnProperty(i))
					continue;

				var change = changes[i];
				if (change.calId != undefined) evt.calendarId = change.calId;
				if (change.owner === null || change.owner != undefined) evt.calendarOwner = change.owner;
				if (change.subject != undefined) evt.subject = change.subject;
				if (change.location != undefined) evt.location = change.location;
				if (change.resourceId != undefined) {
					evt.resourceId = change.resourceId;
					if (evt.resourceId === "") {
						evt.resourceName = "";
					}
				}
				if (change.start != undefined) {
					startChanged = true;
					evt.start = {
						dt: moment.tz(moment(change.start), "UTC").add(moment(change.start).utcOffset(), "minutes"), // Save in moment; converted to JSDate below
						tz: $scope.timeZoneStart.location
					};
				}
				if (change.end != undefined) {
					endChanged = true;
					evt.end = {
						dt: moment.tz(moment(change.end), "UTC").add(moment(change.end).utcOffset(), "minutes"), // Save in moment; converted to JSDate below
						tz: $scope.timeZoneEnd.location
					};
				}
				if (change.allDay != undefined) evt.allDay = change.allDay;
				if (change.availability != undefined) evt.availability = change.availability;
				if (change.reminderIndex != undefined) evt.reminderIndex = change.reminderIndex;
				if (change.emailNotificationEnabled != undefined) evt.emailNotificationEnabled = change.emailNotificationEnabled;
				if (change.emailNotification != undefined) evt.emailNotification = change.emailNotification;
				if (change.description != undefined) evt.description = change.description;
				if (change.isPrivate != undefined) evt.isPrivate = change.isPrivate;
				if (change.organizerName != undefined) {
					if (change.organizerName.indexOf(change.owner) > -1)
						evt.organizerName = change.organizerName;
					else {
						if (evt.organizerName.indexOf("@") > -1) {
							var domain = evt.organizerName.substring(evt.organizerName.indexOf("@"));
							evt.organizerName = change.organizerName + "@" + domain;
						} else {
							evt.organizerName = change.organizerName;
						}
					}
				}
				if (change.organizerEmail != undefined) evt.organizerEmail = change.organizerEmail;
				if (change.attendees != undefined) {
					evt.attendees = change.attendees;
					if (evt.attendees.length == 0) {
						evt.organizerName = null;
						evt.organizerEmail = null;
					}
				}
				if (change.categories != undefined) evt.categories = change.categories;
				if (change.recurrence != undefined) evt.recurrence = change.recurrence;
				if (change.removedFiles) removedFiles = change.removedFiles;
				if (change.attachmentsAdded && $scope.canEditAttachments()) evt.attachmentGuid = vm.attachmentGuid;
			}

			if (!startChanged) {
				evt.start.dt = moment.tz(moment(vm.originalDetails.start.dt), "UTC")
					.add(moment(vm.originalDetails.start.dt).utcOffset(), "minutes"); // Save in moment; converted to JSDate below
			}
			if (!endChanged) {
				evt.end.dt = moment.tz(moment(vm.originalDetails.end.dt), "UTC")
					.add(moment(vm.originalDetails.end.dt).utcOffset(), "minutes"); // Save in moment; converted to JSDate below
			}

			if (!evt.allDay) {
				// Format the moment dates as JSDate
				evt.start.dt = evt.start.dt.toISOString();
				evt.end.dt = evt.end.dt.toISOString();
			}

			if ($location.search().instanceId) {
				var instanceDate = moment.utc($location.search().instanceId, "YYYYMMDDHHmmss");
				evt.recurrenceId = instanceDate;
			}

			coreDataCalendar.ignoreCalendarEventModified.requested = moment();

			evt.meetingUrl = vm.hasMeeting && !vm.meetingRemoved
				? $scope.details.meetingUrl
				: null;

			var params = JSON.stringify(evt);
			$rootScope.spinner.show();
			deleteRemovedAttachedFiles()
				.then(() =>
					$http
						.post(`~/api/v1/calendars/events/save/${owner}/${calId}/${eventId}/${notifyAttendees}`, params)
				)
				.then(onSaveSuccess224, errorMessageService.showErrorMessage)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess224() {
				vm.eventForm.$setPristine();
				$rootScope.$broadcast("calendarRefresh");
				$timeout(function () { window.close(); });
			}

			function deleteRemovedAttachedFiles() {
				if (!removedFiles) {
					var defer = $q.defer();
					defer.resolve();
					return defer.promise;
				}
				var removeParams = JSON.stringify({
					folderId: calSource.folderId,
					owner: owner,
					removeFileReferences: removedFiles
				});
				return $http.post("~/api/v1/filestorage/remove-attached-file", removeParams);
			}
		}

		function eventOngoing() {
			var numInvitees = 0;
			if ($scope.eventInfo && $scope.eventInfo.info) {
				numInvitees = $.grep($scope.eventInfo.info.attendees, function (attendee) { return attendee.isOrganizer === false && !attendee.isResource; }).length;
			}
			return numInvitees > 0;
		}

		function deleteAppointment(ev, deleteAll, isInstance) {
			// Please do not change this into a generic confirmation modal
			// The generic confirmation doesn't support the notify switch
			var notify = eventOngoing(isInstance) && $scope.details.status !== $scope.eventStatus.Cancelled;
			var changes = [];
			if (deleteAll && $scope.isRecurring) {
				var seriesStart = vm.editSeries ? vm.originalDetails.start : vm.originalDetails.firstInstanceStart;
				var seriesEnd = vm.editSeries ? vm.originalDetails.end : vm.originalDetails.firstInstanceEnd;
				changes.push({ start: moment.tz(seriesStart.dt, seriesStart.tz) });
				changes.push({ end: moment.tz(seriesEnd.dt, seriesEnd.tz) });
			}
			var isSeries = $scope.isRecurring && deleteAll;
			CheckEventHasPast(changes, isSeries, true)
				.then(function (data) {
					var params = {
						locals: {
							items: 1,
							deleteNotify: notify,
							notifyOrganizer: $scope.details.isOrganizer === false,
							isPast: data
						},
						controller: "calendarDeleteDialogController",
						controllerAs: "ctrl",
						templateUrl: "app/calendars/modals/calendar-delete.dlg.html",
						targetEvent: ev,
						clickOutsideToClose: false
					};
					$mdDialog
						.show(params)
						.then(
							function (result) { onDeleteConfirmed(result.notify); },
							function () { });
				}, errorHandling.report);

			function onDeleteConfirmed(notify2) {
				var params = "";
				if (!deleteAll) {
					var instanceId = moment($location.search().instanceId, "YYYYMMDDHHmmss");
					params = JSON.stringify({ instanceStart: instanceId.format("YYYY-MM-DD") + "T" + instanceId.format("HH:mm:ss") + "Z" });
				}

				coreDataCalendar.ignoreCalendarEventRemoved.requested = moment();
				coreDataCalendar.ignoreCalendarEventModified.requested = coreDataCalendar.ignoreCalendarEventRemoved.requested;
				if ($scope.details.status === $scope.eventStatus.Cancelled) 
					notify2 = false;
				$rootScope.spinner.show();
				$http
					.post("~/api/v1/calendars/events/delete/" + vm.owner + "/" + vm.calId + "/" + vm.id + "/" + notify2, params)
					.then(onDeleteSuccess, errorMessageService.showErrorMessage)
					.finally($rootScope.spinner.hide);

				function onDeleteSuccess() {
					$rootScope.$broadcast("calendarRefresh");
					vm.eventForm.$setPristine();
					window.close();
				}
			}
		}

		function cancel() {
			delete localStorage.apptPopout;
			window.close();
		}
		function getInstanceId() {
			var instanceId = null;

			if ($location.search().instanceId) {
				var instanceDate = moment.utc($location.search().instanceId, "YYYYMMDDHHmmss");

				instanceId = instanceDate.toISOString();
			}
			return instanceId;
		}
		function acceptInvite() {
			$rootScope.spinner.show();

			$http
				.post(`~/api/v1/calendars/meeting-accept/${vm.owner}/${vm.calId}/${vm.id}/${getInstanceId()}`)
				.then(onAcceptSuccess, errorMessageService.showErrorMessage)
				.finally($rootScope.spinner.hide);

			function onAcceptSuccess() {
				vm.eventForm.$setPristine();
				//window.close();
			}
		}

		function tentativelyAcceptInvite() {
			$rootScope.spinner.show();

			$http
				.post(`~/api/v1/calendars/meeting-tentatively-accept/${vm.owner}/${vm.calId}/${vm.id}/${getInstanceId()}`)
				.then(onAcceptSuccess, errorMessageService.showErrorMessage)
				.finally($rootScope.spinner.hide);

			function onAcceptSuccess() {
				vm.eventForm.$setPristine();
				//window.close();
			}
		}

		function declineInvite() {
			$rootScope.spinner.show();
			$http
				.post(`~/api/v1/calendars/meeting-decline/${vm.owner}/${vm.calId}/${vm.id}/${getInstanceId()}`)
				.then(onDeclineSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onDeclineSuccess() {
				vm.eventForm.$setPristine();
				//window.close();
			}
		}

		//#endregion

		//#region DateTimePicker functions (blur and changes)
		function constrainEndTime(startChanging, duration) {
			if (!$scope.start)
				return;

			$scope.endBeforeStart = false;

			var mStart = getTimeZoneMoment($scope.start, $scope.timeZoneStart);
			var minEnd = moment(mStart).add(15, "minutes");
			var tempEnd, relativeOffset;
			var localOffset = moment(new Date()).local().utcOffset();

			if (duration) {
				tempEnd = $scope.timeZoneStart
					? moment.tz(mStart, $scope.timeZoneStart.location).utc().add(duration)
					: moment(mStart).utc().add(duration);
				recalculateEnd();
				if (tempEnd - mStart > 0) {
					tempEnd = getTimeZoneMoment($scope.start, $scope.timeZoneEnd).add(duration);
					recalculateEnd();
				}
			} else if (!$scope.end) {
				tempEnd = getTimeZoneMoment(mStart, $scope.timeZoneEnd).add(1, "hours");
				recalculateEnd();
			} else {
				tempEnd = getTimeZoneMoment($scope.end, $scope.timeZoneEnd);
				if (startChanging && tempEnd.toDate() < mStart.toDate()) {
					tempEnd = getTimeZoneMoment(mStart, $scope.timeZoneEnd).add(1, "hours");
					recalculateEnd();
				}
				//else if (tempEnd.toDate() < minEnd.toDate()) {
				//	tempEnd = getTimeZoneMoment(minEnd, $scope.timeZoneEnd);
				//	recalculateEnd();
				//}
			}

			$scope.endBeforeStart = isEndBeforeStart();

			function recalculateEnd() {
				$scope.end = tempEnd.toDate();
			}

			function isEndBeforeStart() {
				var mjs = moment($scope.start);
				var start = moment.tz({
					year: mjs.year(),
					month: mjs.month(),
					date: mjs.date(),
					hours: mjs.hours(),
					minutes: mjs.minutes(),
					seconds: mjs.seconds()
				}, $scope.timeZoneStart.location);
				mjs = moment($scope.end);
				var end = moment.tz({
					year: mjs.year(),
					month: mjs.month(),
					date: mjs.date(),
					hours: mjs.hours(),
					minutes: mjs.minutes(),
					seconds: mjs.seconds()
				}, $scope.timeZoneEnd.location);
				return end < start;
			}
		}

		function getTimeZoneMoment(date, timezone) {
			if (!date)
				return date;
			if (date instanceof moment)
				return timezone ? moment.tz(date, timezone.location) : moment(date);

			var dateStr = date.getFullYear() + "-" +
				("0" + (date.getMonth() + 1)).slice(-2) + "-" +
				("0" + date.getDate()).slice(-2) + "T" +
				("0" + date.getHours()).slice(-2) + ":" +
				("0" + date.getMinutes()).slice(-2) + ":" +
				("0" + date.getSeconds()).slice(-2);
			return timezone ? moment.tz(date, timezone.location) : moment(dateStr);
		}

		var minStart = moment("1900-01-01").toDate();
		$scope.$watch("start", function (newValue, oldValue) {
			if (!$scope.start) return;

			if (skipNextStartWatch) {
				skipNextStartWatch = false;
				return;
			}

			if ($scope.start < minStart) {
				$scope.start = minStart;
			}

			var duration = null;
			if (oldValue) {
				var mStart = getTimeZoneMoment(oldValue, $scope.timeZoneStart).tz("UTC");
				var mEnd = getTimeZoneMoment($scope.end, $scope.timeZoneEnd).tz("UTC");
				duration = moment.duration(mEnd.diff(mStart));
			}
			constrainEndTime(true, duration);

			if ($scope.isRecurring && vm.editSeries) {
				if ($scope.recurUntilDate < $scope.start)
					$scope.recurUntilDate = moment($scope.start).add(1, "y").startOf("day").add(1, "d").add(-1, "s").utc().toDate();
				switch ($scope.currentRecurrence.id) {
					default:
					case 0:
					case 4:
						break;
					case 5:
						var preWeeklyFreqDay = moment(oldValue || $scope.start).day();
						var newWeeklyFreqDay = moment($scope.start).day();
						if ($scope.calEvCtrl.weeklyDays[preWeeklyFreqDay] && !$scope.calEvCtrl.weeklyDays[newWeeklyFreqDay]) {
							$scope.calEvCtrl.weeklyDays[preWeeklyFreqDay] = false;
						}
						$scope.calEvCtrl.weeklyDays[newWeeklyFreqDay] = true;
						break;
					case 6:
						if ($scope.monthlyFreq === 0) {
							var newMonthFreqDay = moment($scope.start).date();
							var newYearlyFreqMonthDate = moment($scope.start).date();
							if ($scope.monthlyFreqDay !== newMonthFreqDay) {
								$scope.monthlyFreqDay = newMonthFreqDay;
								$scope.recurrenceChanged = true;
							}
						} else {
							var isSpecificDay = $scope.monthlyFreqPeriodDay != $scope.daysOfWeek[7] &&
								$scope.monthlyFreqPeriodDay != $scope.daysOfWeek[8] &&
								$scope.monthlyFreqPeriodDay != $scope.daysOfWeek[9];
							if (isSpecificDay && $scope.daysOfWeek[moment($scope.start).day()] !== $scope.monthlyFreqPeriodDay) {
								$scope.monthlyFreqPeriodDay = $scope.daysOfWeek[moment($scope.start).day()];
								$scope.recurrenceChanged = true;
							}
						}
						break;
					case 7:
						var newYearlyFreqMonth = $scope.months[moment($scope.start).month()].id;
						var newYearlyFreqMonthDate = moment($scope.start).date();
						if (vm.yearlyFreqMonthDate !== newYearlyFreqMonthDate) {
							vm.yearlyFreqMonthDate = newYearlyFreqMonthDate;
							$scope.recurrenceChanged = true;
						}
						if ($scope.yearlyFreqMonth !== newYearlyFreqMonth) {
							$scope.yearlyFreqMonth = newYearlyFreqMonth;
							$scope.recurrenceChanged = true;
						}
						break;

				}
			}
			checkForConflicts();
		});

		$scope.$watch("end", function (newValue, oldValue) {
			if (!newValue && !oldValue || newValue === oldValue)
				return;

			if (newValue && oldValue && newValue.getTime() === oldValue.getTime())
				return;

			if (skipNextEndWatch) {
				skipNextEndWatch = false;
				return;
			}

			if ($scope.start) {
				constrainEndTime();
				checkForConflicts();
			}
		});

		$scope.$watch("timeZoneStart.location", function (newValue, oldValue) {
			if ((!newValue && !oldValue) || oldValue === newValue)
				return;
			if ($scope.start) {
				var diff = null;
				if (oldValue) {
					var start = moment.tz($scope.start, oldValue).tz("UTC");
					var end = $scope.timeZoneEnd
						? moment.tz($scope.end, $scope.timeZoneEnd.location).tz("UTC")
						: moment($scope.end).tz("UTC");
					diff = moment.duration(end.diff(start));
				}
				constrainEndTime(false, diff);
				checkForConflicts();
			}
			convertProposalsForDisplay();
		});

		$scope.$watch("timeZoneEnd.location", function (newValue, oldValue) {
			if ((!newValue && !oldValue) || oldValue === newValue)
				return;
			if ($scope.start) {
				var diff = null;
				if (oldValue) {
					var start = $scope.timeZoneStart
						? moment.tz($scope.start, $scope.timeZoneStart.location).tz("UTC")
						: moment($scope.start).tz("UTC");
					var end = moment.tz($scope.end, oldValue).tz("UTC");
					diff = moment.duration(end.diff(start));
				}
				constrainEndTime(false, diff);
				checkForConflicts();
			}
			convertProposalsForDisplay();
		});

		$scope.$watch("currentResource", function (newValue, oldValue) {
			if (!newValue || !oldValue || newValue === oldValue) return;
			onValueChanged();
			checkForConflicts();
		});

		$scope.$watch("attendees", function (newValue, oldValue) {
			if (!newValue || !oldValue || newValue === oldValue) return;
			checkForConflicts();
		});

		$scope.$watch("allDay", function (newValue, oldValue) {
			if (!newValue || !oldValue || newValue === oldValue) return;
			checkForConflicts();
		});

		$scope.$watch("recurUntilDate", function () { $timeout($scope.$applyAsync); });

		watchersRegistered = true;

		//#endregion

		function toggleRecurringDelete() {
			$scope.showRecurringDelete = !$scope.showRecurringDelete;
		}

		function onSourceChanged(source) {
			vm.isSourceOwner = source.owner === userDataService.user.username;
			apiCategories.categoryOwner = `${source.owner}@${userDataService.user.domain}`;
			onCategoryListModified();
		}

		function openRecipientsModal(form, ev) {
			$q
				.all([coreDataCategories.init(), $http.get("~/api/v1/contacts/sources")])
				.then(onSuccess, onFailure);

			function onSuccess(success) {
				var params = {
					locals: {
						sources: success[1].data.sharedLists.concat($.map(coreDataContacts.categories(),
							function (cat) { return { itemID: "category", displayName: cat.name } })),
						type: "ATTENDEES"
					},
					controller: "emailRecipientsController",
					controllerAs: "ctrl",
					templateUrl: "app/shared/modals/recipients.dlg.html",
					targetEvent: ev,
					clickOutsideToClose: false
				};

				$mdDialog
					.show(params)
					.then(onSuccess2, onFailure);
			}

			function onSuccess2(success) {
				// Add address if not already included
				angular.forEach(success.selectedContacts, function (contact) {
					if (contact.isGroup) {
						$http.get(`~/api/v1/contacts/unpack-group/${contact.id}/${contact.sourceAccount}`)
							.then(
								function (unpackSuccess) {
									for (let i = 0; i < unpackSuccess.data.emailData.length; i++) {
										const member = unpackSuccess.data.emailData[i];
										addNewAttendee({
											name: member.displayAs,
											email: member.emailAddress,
											status: 0,
											conflict: false,
											nonavail: null
										});
									}
								},
								errorHandling.report);
					} else {
						addNewAttendee({
							name: contact.displayAs,
							email: contact.emailAddressList[0],
							status: 0,
							conflict: false,
							nonavail: null
						});
					}

					function addNewAttendee(newAttendee) {
						if (!lowercaseCompare($scope.currentCalendar.owner, coreData.user.domain) &&
							lowercaseCompare(newAttendee.email, $scope.currentCalendar.owner + "@" + coreData.user.domain)) {
							return;
						}

						var found = false;
						for (let i = 0; i < $scope.attendees.length; ++i) {
							if ($scope.attendees[i].email.toLowerCase() === newAttendee.email.toLowerCase()) {
								found = true;
								break;
							}
						}
						if (!found) {
							$scope.attendees.push(newAttendee);
							$scope.attendeesChanged = true;
						}
					}
				});

				if ($scope.organizerName === "" && lowercaseCompare($scope.currentCalendar.owner, coreData.user.username))
					$scope.organizerName = coreData.user.displayName;
				else {
					var contacts = coreDataContacts.getContacts();
					var ownerName = $.grep(contacts, function (du) { return lowercaseCompare(du.userName, $scope.currentCalendar.owner); });
					if (ownerName.length > 0) {
						$scope.organizerName = ownerName[0].fullName;
					}
				}

				if ($scope.organizerEmail === "") {
					$scope.organizerEmail = coreData.user.emailAddress;
					if ($scope.organizerEmail.indexOf("@") === 0 && !lowercaseCompare($scope.currentCalendar.owner, coreData.user.domain)) {
						$scope.organizerEmail += "@" + coreData.user.domain;
					}
				}

				onValueChanged(form);
				checkForConflicts();
			}

			function onFailure() {
				// Do nothing
			}
		}

		function openResourceRecipeintsModal(form, ev) {
			var resources = coreDataCalendar.getResources();
			var conferenceRooms = [];
			var equipment = [];
			resources.forEach(function (resource) {
				if (resource.sharedResourceType === 2)
					conferenceRooms.push(resource);
				else if (resource.sharedResourceType === 3)
					equipment.push(resource);
			});

			var sharedLists = [];
			if (conferenceRooms.length > 0) {
				sharedLists.push({
					enabled: true,
					access: 0,
					displayName: $translate.instant("SHARED_RESOURCES_CONFERENCE_ROOMS"),
					domain: coreData.user.domain,
					itemID: "conferencerooms",
					isPrimary: false,
					shareType: "",
					folderId: 0,
					isSharedItem: true,
				});
			}
			if (equipment.length > 0) {
				sharedLists.push({
					enabled: true,
					access: 0,
					displayName: $translate.instant("SHARED_RESOURCES_EQUIPMENT"),
					domain: coreData.user.domain,
					itemID: "equipment",
					isPrimary: false,
					shareType: "",
					folderId: 0,
					isSharedItem: true,
				});
			}

			var params = {
				locals: {
					sources: sharedLists,
					type: "RESOURCES"
				},
				controller: "emailRecipientsController",
				controllerAs: "ctrl",
				templateUrl: "app/shared/modals/recipients.dlg.html",
				targetEvent: ev,
				clickOutsideToClose: false
			};

			$mdDialog
				.show(params)
				.then(success, failure);

			function success(success) {
				angular.forEach(success.selectedContacts, function (resource) {
					addNewResource({
						name: resource.displayAs,
						email: resource.emailAddressList[0],
						status: 0,
						conflict: false,
						resourceType: resource.sharedResourceType,
						isResource: true,
					});
				});

				function addNewResource(newResource) {
					var found = false;
					for (let i = 0; i < $scope.resources.length; ++i) {
						if ($scope.resources[i].email.toLowerCase() === newResource.email.toLowerCase()) {
							found = true;
							break;
						}
					}

					if (!found) {
						$scope.resources.push(newResource);
						$scope.attendeesChanged = true;
					}
					handleResourceAddAndLocation(newResource);
				}
			}

			function failure(failure) {
				// Do nothing
			}
		}

		function viewAvailability(ev) {
			var info = angular.copy($scope.eventInfo);
			// merge attendees and resources into info.info
			info.info.attendees = $scope.attendees.concat($scope.resources);
			info.info.allDay = $scope.allDay;


			var isOrganizerFound = info.info.attendees.some(att => att.email && att.email.toUpperCase() === $scope.organizerEmail.toUpperCase());

			if (!isOrganizerFound) {
				var orgname = $scope.organizerName || userDataService.user.displayName;
				var orgemail = $scope.organizerEmail || userDataService.user.emailAddress;
				if ($scope.currentCalendar.isSharedItem) {
					if ($scope.currentCalendar.sharedResourceType === 0) {
						orgname = userDataService.user.displayName;
						orgemail = userDataService.user.emailAddress;
					} else {
						orgname = $scope.currentCalendar.owner;
						orgemail = $scope.currentCalendar.owner + orgemail.substring(orgemail.indexOf("@"));
					}
				}

				var org = [{
					name: orgname,
					email: orgemail,
					status: 1,
					conflict: false,
					nonavail: Array(25 * 4).fill(false),
				}];
				info.info.attendees = org.concat(info.info.attendees);
			}

			var params = {
				locals: { eventInfo: info, date: $scope.isRecurring ? $scope.instanceStart : $scope.start },
				controller: "calAvailabilityController",
				controllerAs: "ctrl",
				templateUrl: "app/calendars/modals/calendar-availability.dlg.html",
				targetEvent: ev,
				clickOutsideToClose: false
			};

			$mdDialog.show(params);
		}

		window.onbeforeunload = function () {
			if (vm.eventForm.$dirty)
				return true;
		};

		function goToSeries() {
			$location.$$search = [];
			vm.loadSeries();
		};

		$scope.potentialConflict = false;
		var checkingForConflicts = false;
		const checkForSelectedProposal = function () {
			if ($scope.proposals) {
				$scope.proposals.forEach(prop => {
					prop.selected = prop.start.toISOString() === $scope.start.toISOString() && prop.end.toISOString() === $scope.end.toISOString();
				})
			}
		}
		function checkForConflicts() {
			if (checkingForConflicts) return;
			if (!$scope.eventInfo) return;
			checkForSelectedProposal();
			checkingForConflicts = true;
			var attendees = angular.copy($scope.attendees || []);
			if ($scope.resources) $scope.resources.forEach(resource => attendees.push(resource));

			var info = angular.copy($scope.eventInfo);
			var isOrganizerFound = false;
			for (var i = 0; i < info.info.attendees.length; i++) {
				if (info.info.attendees[i].email.toUpperCase() === $scope.organizerEmail.toUpperCase()) {
					isOrganizerFound = true;
					break;
				}
			}
			if (!isOrganizerFound) {
				var orgname = $scope.organizerName || userDataService.user.displayName;
				var orgemail = $scope.organizerEmail || userDataService.user.emailAddress;
				if ($scope.currentCalendar.isSharedItem) {
					if ($scope.currentCalendar.sharedResourceType === 0) {
						orgname = userDataService.user.displayName;
						orgemail = userDataService.user.emailAddress;
					} else {
						orgname = $scope.currentCalendar.owner;
						orgemail = $scope.currentCalendar.owner + orgemail.substring(orgemail.indexOf("@"));
					}
				}

				var org = {
					name: orgname,
					email: orgemail,
					status: 0,
					conflict: false,
					nonavail: Array(25 * 4).fill(false),
					isResource: $scope.currentCalendar.sharedResourceType === 0
				};
				isOrganizerFound = true;
				attendees.push(org);
			}

			if (attendees.length === 0) {//} || (attendees.length === 1 && isOrganizerFound)) {
				$scope.potentialConflict = false;
				return;	// If there are no attendees, no need to do these checks
			}

			var availabilityDate = moment.tz([
				$scope.start.getYear() + 1900,
				$scope.start.getMonth(),
				$scope.start.getDate(),
				$scope.start.getHours(),
				$scope.start.getMinutes(),
				0, 0], userTimeService.userTimeZone.location);
			var participants = $.map(attendees, function (attendee) { return attendee.email; });
			if ($scope.allDay) { availabilityDate.hour(0).minute(0).second(0); }
			var params = JSON.stringify({
				date: availabilityDate.toISOString(),
				emailAddresses: participants,
				uidToIgnore: (vm.id !== "new" && vm.id !== null) ? vm.id : null
			});
			$rootScope.spinner.show(1000);
			$http
				.post("~/api/v1/calendars/user-availability", params)
				.then(function (result) { onGetUserAvailability(result, availabilityDate); }, errorHandling.report)
				.finally(function () {
					$rootScope.spinner.hide();
					checkingForConflicts = false;
				});

			function onGetUserAvailability(result, adjustedDate) {
				var startTime = moment.tz([
					$scope.start.getYear() + 1900,
					$scope.start.getMonth(),
					$scope.start.getDate(),
					$scope.start.getHours(),
					$scope.start.getMinutes(),
					0, 0], userTimeService.userTimeZone.location);
				var endTime = moment.tz([
					$scope.end.getYear() + 1900,
					$scope.end.getMonth(),
					$scope.end.getDate(),
					$scope.end.getHours(),
					$scope.end.getMinutes(),
					0, 0], userTimeService.userTimeZone.location);
				if (endTime < startTime)
					endTime = startTime;

				var busyHours = Array(25 * 4).fill(false);
				var numSlots = Math.ceil(endTime.diff(startTime, "minutes") / 15);
				var hour = startTime.hour();
				if (startTime.date() < adjustedDate.date())
					hour -= 24;
				var slot = hour * 4 + startTime.minute() / 15;
				do {
					if (slot >= 0)
						busyHours[slot] = true;
					++slot;
					if (slot >= busyHours.length)
						break;
					--numSlots;
				} while (numSlots > 0);

				$scope.potentialConflict = false;
				for (let i = 0; i < busyHours.length; ++i) {
					if (busyHours[i] === false)
						continue;

					for (let j = 0; j < result.data.length; ++j) {
						if (result.data[j].busyHours == null)
							continue;	// Most likely an external attendee with no busy hours information
						var foundAttendee = attendees.find(attendee => attendee.email === result.data[j].emailAddress);
						if (!foundAttendee || foundAttendee.status === 1)
							continue;	// No conflicts for an accepted attendee
						if (result.data[j].busyHours[i] === true) {
							$scope.potentialConflict = true;
							return;
						}
					}
				}
			}
		}

		function copyLink() {
			$("#onlineMeetingLink").select().trigger("focus");
			document.execCommand("copy");
			successHandling.report("COPIED_TO_CLIPBOARD");
		}

		// #region Online Meeting functions
		function createTempMeeting(recreating) {
			if ((vm.hasMeeting && !vm.meetingMissing) || !vm.allowTeamWorkspaces || !canEdit() || $scope.endBeforeStart)
				return;

			const meeting = {
				title: $scope.subject,
				allowGuestUsers: true, // This needs to be true to allow external attendees to participate in the meeting
				attendeesCanStart: true,
				endMeetingBehavior: 0,
				uploadPermissionLevel: 0,
				scheduleMeeting: $scope.currentRecurrence.id !== $scope.recurrenceType.ONCE,
				inProcess: true
			};

			let offsetMins = moment($scope.start).utcOffset() - moment.tz($scope.start, $scope.timeZoneStart.location).utcOffset();
			meeting.scheduledStart = moment.tz($scope.start, "UTC").add(offsetMins, "minutes").format();
			meeting.timeZoneStart = $scope.timeZoneStart;

			offsetMins = moment($scope.end).utcOffset() - moment.tz($scope.end, $scope.timeZoneEnd.location).utcOffset();
			meeting.scheduledEnd = moment.tz($scope.end, "UTC").add(offsetMins, "minutes").format();
			meeting.timeZoneEnd = $scope.timeZoneEnd;

			meetingWorkspaces.createNew(meeting)
				.then(
					function (data) {
						meetingWorkspaces.getMeetingDetails(data.id)
							.then(
								function (success) {
									vm.hasMeeting = vm.meetingCreated = true;
									vm.meetingMissing = false;
									vm.onlineMeeting = success;

									vm.onlineMeeting.uploadPermissionLevel =
										(vm.onlineMeeting.uploadPermissionLevel === null || vm.onlineMeeting.uploadPermissionLevel === undefined) ? 0 : vm.onlineMeeting.uploadPermissionLevel;
									vm.onlineMeeting.endMeetingBehavior =
										(vm.onlineMeeting.endMeetingBehavior === null || vm.onlineMeeting.endMeetingBehavior === undefined) ? 0 : vm.onlineMeeting.endMeetingBehavior;

									const oldMeetingUrl = $scope.details.meetingUrl;
									const urlBase = window.location.href.substring(0, window.location.href.indexOf("/interface/"));
									$scope.details.meetingUrl = `${urlBase}/interface/meeting#/${vm.onlineMeeting.fullId}`;

									let description = $scope.editorScope.getHtml() || "";
									if (recreating) {
										description = description.replaceAll(oldMeetingUrl, $scope.details.meetingUrl);
									} else {
										let insert = $filter("translate")("CALENDAR_WORKSPACE_DESCRIPTION", { mtgUrl: $scope.details.meetingUrl });
										insert = $("<div>").html(insert).text(); // Translate HTML encodes the string, so we need to undo that
										insert = $("<div>").append($(insert).attr("id", "meetingLink")).html();

										description = `${description}<div><br></div><hr id="meeting">${insert}`;
									}

									$scope.editorScope.setHtml(description);
									setFormDirty(vm.eventForm);
								},
								errorHandling.report
							);
					},
					errorHandling.report);
		}

		function deleteMeeting() {
			if (!vm.hasMeeting || !vm.allowTeamWorkspaces || !canEdit())
				return $q.when();

			const defer = $q.defer();

			meetingWorkspaces.deleteMeetings([vm.onlineMeeting.id], true)
				.then(defer.resolve, defer.reject);

			return defer.promise;
		}

		function editMeetingDisabled() {
			if (vm.meetingCreated) {
				return false;
			}
			const disable = $location.search().instanceId && !vm.editSeries &&
				(!vm.onlineMeeting.scheduledStart || !vm.onlineMeeting.scheduledEnd);
			return disable;
		}

		function needEndBehavior() {
			if (vm.meetingCreated) {
				if ($location.search().instanceId && !vm.editSeries)
					return true;
				return $scope.currentRecurrence.id === $scope.recurrenceType.ONCE;
			}

			return vm.onlineMeeting.scheduledStart && vm.onlineMeeting.scheduledEnd;
		}

		function removeMeeting() {
			const description = $("<div>").html($scope.editorScope.getHtml());

			var meetingLink = description.find("#meetingLink");
			meetingLink.prev().filter("div").has("br").remove();
			var hr = meetingLink.prev().filter("hr");
			hr.prev().filter("div").has("br").remove();
			meetingLink.remove();
			hr.remove();

			$scope.editorScope.setHtml(description.html());

			if (vm.meetingCreated) {
				vm.onlineMeeting = null;
				vm.hasMeeting = false;
				vm.meetingMissing = false;
				$scope.details.meetingUrl = "";
				vm.meetingCreated = false;
			} else {
				vm.meetingRemoved = true;
			}

			setFormDirty(vm.eventForm);
		}

		function updateMeeting() {
			if (!vm.hasMeeting || !vm.allowTeamWorkspaces || !canEdit())
				return $q.when();

			if (editMeetingDisabled())
				return $q.when();

			const defer = $q.defer();

			const meeting = vm.onlineMeeting;
			meeting.title = $scope.subject;
			meeting.allowGuestUsers = true; // This needs to be true to allow external attendees to participate in the meeting
			meeting.password = vm.onlineMeeting.password;
			meeting.inProcess = false;

			if ($scope.currentRecurrence.id === $scope.recurrenceType.ONCE || ($location.search().instanceId && !vm.editSeries)) {
				meeting.scheduleMeeting = true;

				let offsetMins = moment($scope.start).utcOffset() - moment.tz($scope.start, $scope.timeZoneStart.location).utcOffset();
				meeting.scheduledStart = moment.tz($scope.start, "UTC").add(offsetMins, "minutes").format();
				meeting.timeZoneStart = $scope.timeZoneStart;

				offsetMins = moment($scope.end).utcOffset() - moment.tz($scope.end, $scope.timeZoneEnd.location).utcOffset();
				meeting.scheduledEnd = moment.tz($scope.end, "UTC").add(offsetMins, "minutes").format();
				meeting.timeZoneEnd = $scope.timeZoneEnd;
			} else {
				meeting.scheduleMeeting = false;

				meeting.scheduledStart = null;
				meeting.timeZoneStart = null;
				meeting.scheduledEnd = null;
				meeting.timeZoneEnd = null;
			}

			if (vm.onlineMeeting.archived)
				meetingWorkspaces.unarchiveMeetings([vm.onlineMeeting.id])
					.then(doUpdateMeeting, doUpdateMeeting);
			else
				doUpdateMeeting();

			return defer.promise;

			function doUpdateMeeting() {
				meetingWorkspaces.update(meeting)
					.then(defer.resolve, defer.reject);
			}
		}

		// #endregion

		// #region Proposals
		function acceptProposal(proposal) {
			if (watchersRegistered) {
				skipNextStartWatch = true;
				skipNextEndWatch = true;
			}

			try {
				$scope.proposals.forEach(prop => {
					prop.selected = prop.name === proposal.name;
				});
				$scope.start = proposal.start;
				$scope.end = proposal.end;
			}
			catch (err) { }

			setFormDirty();
		}

		function convertProposalsForDisplay(checkState) {
			$scope.proposals = [];

			if (checkState && $stateParams.data && $stateParams.data.proposal) {
				$stateParams.data.proposal.start = convertMeetingTime($stateParams.data.proposal.start, vm.originalDetails.allDay);
				$stateParams.data.proposal.end = convertMeetingTime($stateParams.data.proposal.end, vm.originalDetails.allDay);
			}

			if (!vm.originalDetails || !vm.originalDetails.proposals || !vm.originalDetails.proposals.length)
				return;

			const original = {};
			original.name = $translate.instant("ORIGINAL_TIME");

			original.start = convertMeetingTime(vm.originalDetails.start, vm.originalDetails.allDay);
			original.end = convertMeetingTime(vm.originalDetails.end, vm.originalDetails.allDay);
			$scope.proposals.push(original);

			for (let i = 0; i < vm.originalDetails.proposals.length; i++) {
				const proposal = $.extend({}, vm.originalDetails.proposals[i]);
				proposal.start = convertMeetingTime({ dt: proposal.start, tz: vm.originalDetails.start.tz }, vm.originalDetails.allDay);
				proposal.end = convertMeetingTime({ dt: proposal.end, tz: vm.originalDetails.start.tz }, vm.originalDetails.allDay);

				proposal.name = getAttendeeName(proposal.email);

				$scope.proposals.push(proposal);
			}
			function convertMeetingTime(time, isAllDay) {
				if (!time) return "";
				if (!isAllDay)
					return userTimeService.convertLocalToUserTimeMoment(time.dt).toDate(); // moment(time).toDate();
				else {
					var mjs = moment(time.dt.substr(0, 19));
					var momentDate = moment.tz({
						year: mjs.year(),
						month: mjs.month(),
						date: mjs.date(),
						hour: mjs.hour(),
						minute: mjs.minute(),
						second: 0
					}, time.tz);
					var momentDate2 = moment({ year: momentDate.year(), month: momentDate.month(), date: momentDate.date() }).toDate();
					return momentDate2;
				}
			};

			function getAttendeeName(email) {
				email = email.toLowerCase();

				for (let i = 0; i < $scope.attendees.length; i++) {
					if ($scope.attendees[i].email.toLowerCase() === email)
						return $scope.attendees[i].name || email;
				}

				return email;
			}

		}
		// #endregion
		function setForm(form) {
			vm.eventForm = form;
		}

		function setFormDirty(form) {
			vm.eventForm.$setDirty();
			$scope.$applyAsync();
		}

		function linkifyPlainText(text) {
			var added = {};
			var textToAppend = "";
			var textToPrepend = "";
			var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
			var youTube1 = /youtube\.com\/(?:watch\?v=|embed\/)([a-zA-Z0-9-_]+)/gi;
			var youTube2 = /youtu\.be\/([a-zA-Z0-9-_]+)/gi;

			// The API returns HTML encoded plain text descriptions when there is no HTML description. To prevent
			// issues with encoded characters after a URL, we need to decode the text first.
			var plainText = $("<div>").html(text).text();

			var replaced = "";
			var lastEnd = 0;
			for (var match of plainText.matchAll(urlRegex)) {
				var leading = plainText.substr(lastEnd, match.index - lastEnd);
				leading = $("<div>").text(leading).html();
				replaced += leading;

				var url = match[0];

				if (added[url] === undefined) {
					var ytMatch = youTube1.exec(url);
					if (ytMatch) {
						textToAppend += "<a href='" +
							url +
							"' target='_blank' rel='noopener noreferrer'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							ytMatch[1] +
							"/0.jpg'></a>";
					}

					ytMatch = youTube2.exec(url);
					if (ytMatch) {
						textToAppend += "<a href='" +
							url +
							"' target='_blank' rel='noopener noreferrer'><i class='toolsicon toolsicon-youtube sidelogo'></i><img src='http://img.youtube.com/vi/" +
							ytMatch[1] +
							"/0.jpg'></a>";
					}

					added[url] = true;
				}

				replaced += '<a href="' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
				lastEnd = match.index + match[0].length;
			}

			if (lastEnd < plainText.length) {
				replaced += $("<div>").text(plainText.substr(lastEnd)).html();
			}

			return textToPrepend + replaced + textToAppend;
		}

		$scope.trustDescriptionHtml = function (htmlText) {
			// Use this here as the content is sanitized by the server 
			// and ng-bind-html strips inline styling but not style tags.
			return $sce.trustAsHtml(htmlSandboxing.parseCss(htmlText));
		};
		vm.fileUploadedCallback = function () {
			if (vm.eventForm)
				vm.eventForm.$setDirty();
		}

		function exportIcs() {
			let event = vm.owner + "|" + (vm.calId || $scope.currentCalendar.id) + "|" + vm.id;
			const params = JSON.stringify([event]);
			const httpPath = "~/api/v1/calendars/export/download";
			const filename = $filter("translate")("CALENDAR") + ".ics";

			$rootScope.spinner.show();
			coreDataFileStorage.downloadFile(httpPath, filename, params)
				.then(function () { }, errorHandling.report)
				.finally($rootScope.spinner.hide);
			return;
		}
	}
})();
